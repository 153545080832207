import { HeaderTemplete3, HeroTemplte3,  CardIconsTemplate3, MusicTemplate3, DateTemplate3, NosotrosTemplte3, ServiciosTemplte3, MensajeTemplte3, AlbumTemplate3, FamiliaTemplate3, ConfirmacionUbicacionTemplate3, FooterTemplte3} from "./components"
import styleTemplate3 from'./css/template3.module.css'
import Aos from 'aos';
import 'aos/dist/aos.css';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useWindowsSize from "../../Comun/Hooks/useWindowsSize";
import { FireworksComp } from "../../Comun/Componentes/FireworksComp";

export const Invitacion3 = () => {
  let dateEvent = dayjs('2023-10-22');
  useEffect(() => {
       Aos.init({duration:2000});
    }, []);
  
    const { innerHeight } = useWindowsSize();
    const [cardHeight, setCardHeight] = useState();
  
    // let size = Math.floor(innerHeight) - cardHeight;
    let size = Math.floor(innerHeight)
    
  return (
    <>
   <HeaderTemplete3 styleTemplate3={styleTemplate3}/>
   <HeroTemplte3 styleTemplate3={styleTemplate3}  size={size}/>
   <FireworksComp/>
   <CardIconsTemplate3 styleTemplate3={styleTemplate3}/>
   <MusicTemplate3 styleTemplate3={styleTemplate3}/>
   <DateTemplate3 styleTemplate3={styleTemplate3} dateEvent={dateEvent}/>
   <NosotrosTemplte3 styleTemplate3={styleTemplate3}/>
   <ServiciosTemplte3 styleTemplate3={styleTemplate3}/>
   <MensajeTemplte3 styleTemplate3={styleTemplate3}/>
   <AlbumTemplate3 styleTemplate3={styleTemplate3}/>
   <FamiliaTemplate3 styleTemplate3={styleTemplate3}/>
   <ConfirmacionUbicacionTemplate3 styleTemplate3={styleTemplate3}/>
   <FooterTemplte3 styleTemplate3={styleTemplate3}/>
    </>
  )
}