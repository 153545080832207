
import { Link } from "react-router-dom";
export const FooterTemplte3 = ({styleTemplate3}) => {
  return (
    <>
      <footer id={styleTemplate3.footer}>

    <div className={`container ${styleTemplate3.container2}`}>
      <div className={styleTemplate3.copyright}>
        &copy; Copyright <strong><span><Link style={{textDecoration:'none', color: 'white'}} to={'/'}>Click Invitaciones</Link></span></strong>. Todos los derechos reservados. <strong><span> <Link style={{textDecoration:'none', color: 'white'}} to={'/aviso-privacidad'}>Aviso de Privacidad</Link></span></strong>
      </div>
    </div>
  </footer>
    </>
  )
}
