

export const NosotrosTemplte3 = ({styleTemplate3}) => {
  return (
    <>
    
     <section id="about" className={styleTemplate3.about}>
  <div style={{position:'relative'}} className={`container ${styleTemplate3.container2}`} data-aos="fade-up">

    <div className={styleTemplate3['section-title']}>
      <h2>Sofia Valentina con cariño</h2>
      <p>
        Los 15 años abrirán las puertas de tu vida a experiencias que nunca olvidarás. Te deseo lo mejor en este día y que lo celebres con mucha alegría. ¡Felicidades quinceañera!
      </p>
    </div>

    <div className={`row ${styleTemplate3.content}`}>
      <div className="col-lg-6">
        <p>
         Hija de mi corazón, debes estar segura de que este cumpleaños no sólo es importante para ti, sino también para mí, pues desde que llegaste un día como hoy, hiciste que la familia sea mucho mejor de lo que ya era, y totalmente perfecta.
        </p>
        <ul>
          <li style={{fontWeight:'bold'}}><i style={{fontSize:'20px', color:'#920bad'}}  className="bi bi-sun"></i> Un día como hoy nació una flor como tú</li>
          <li style={{fontWeight:'bold'}}><i style={{fontSize:'20px', color:'#920bad'}}  className="bi bi-emoji-heart-eyes"></i> Hoy tú eres la estrella</li>
          <li style={{fontWeight:'bold'}}><i style={{fontSize:'20px', color:'#920bad'}}  className="bi bi-airplane"></i> Con los 15 años, nuevas aventuras llegarán</li>
        </ul>
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0">
        <p>
            Sé que estos son tus quince años, y eso quiere decir que ya eres grande, y que has crecido rápidamente, y yo soy la persona más orgullosa por eso. Te quiero muchísimo hijita linda, ya verás que la pasarás increíble.
        </p>
      </div>
    </div>

  </div>
</section>
    </>
  )
}
