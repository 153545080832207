

export const FooterTemplate6 = ({styleTemplate6}) => {
  return (
    <footer id={styleTemplate6.footer}>
    <div className="container">
      <h3>Click Invitaciones</h3>
      <div className="social-links">
        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
      </div>
      <div className="copyright">
        &copy; Copyright <strong><span>Click Invitaciones</span></strong>. Todos los derechos reservados
      </div>
    </div>
  </footer>
  )
}
