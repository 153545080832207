

export const Principal = () => {
  return (
    <>
        <div className="container">
            <div className="row">
                <div className="col text-center">
                    <h1>"El Gran Día: Consejos y Tendencias para una Boda Inolvidable"</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card p-3 shadow">
                        <p>En nuestro blog de bodas, encontrarás todo lo que necesitas saber para planificar la boda de tus sueños. Desde consejos para elegir el vestido perfecto hasta ideas creativas para la decoración de la recepción, estamos aquí para ayudarte en cada paso del camino. Además, también te mantendremos al tanto de las últimas tendencias en bodas y te ofreceremos inspiración a través de hermosas fotografías de bodas reales. <strong>¡Prepárate para enamorarte del mundo de las bodas!</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
