

export const MensajeTemplate6 = ({styleTemplate6}) => {
  return (
    <section id="cta" className={styleTemplate6.cta}>
      <div className="container">

        <div className="row" data-aos="zoom-out">
          <div className="col-lg-12 text-center text-lg-start">
            <h3>Fernanda & Mario</h3>
            <p>Tus manos son mi caricia, mis acordes cotidianos, te quiero porque tus manos trabajan por la justicia. Si te quiero es porque sos mi amor, mi cómplice y todo y en la calle, codo a codo, somos mucho más que dos. Tus ojos son mi conjuro contra la mala jornada, te quiero por tu mirada que mira y siembra futuro.</p>
          </div>

        </div>

      </div>
    </section>
  )
}
