import imgTemplate2H from "../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg";
import imgTemplate2M from "../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg";

export const NombramientosTemplate4 = ({ styleTemplate4 }) => {
  const nombramientosTemplate4 = [
    {
      nombre: "Ramiro Bustamante Díaz",
      cargo: "Invitado Especial",
      img: imgTemplate2H,
      key: "0",
    },
    {
      nombre: "Delfina Gómiz Álvarez",
      cargo: "Invitado Especial",
      img: imgTemplate2M,
      key: "1",
    },
    {
      nombre: "Mario Lozano Rojas",
      cargo: "Invitado Especial",
      img: imgTemplate2H,
      key: "2",
    },
    {
      nombre: "Yoloxóchitl Bustamante Díaz",
      cargo: "Invitado Especial",
      img: imgTemplate2M,
      key: "3",
    },
  ];

  return (
    <div
      id="team"
    >
      <div
        className={`container`}
        data-aos="fade-up"
      >
        <div >
          <h2 className={styleTemplate4.serviciosTitle}>Invitados Especiales</h2>
        </div>

        <div className="row nombramientos-div">
          {nombramientosTemplate4.map((nombramiento) => (
            <div className="col-lg-6">
              <div
                className={`${styleTemplate4.member} d-flex align-items-start nombramientosMember`}
              >
                <div className={`${styleTemplate4.pic} picNombramientos`}>
                  <img src={nombramiento.img} className={`img-fluid ${styleTemplate4.img} imgNombramiento`} alt="" />
                </div>
                <div className={styleTemplate4.memberInfo}>
                  <h4 className="fw-bold">{nombramiento.nombre}</h4>
                  <span>{nombramiento.cargo}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};
