import imgPalabrasH from '../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg';
import imgPalabrasM from '../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg';

export const palabrasTemplate2 = [
  {
    key: 0,
    nombre: 'Luis',
    cargo: 'Padrino',
    frase:
      'Comienza una nueva vida, una nueva ilusión, un nuevo deseo de hacer las cosas bien.',
    img: imgPalabrasH,
  },
  {
    key: 1,
    nombre: 'Victoria',
    cargo: 'Madrina',
    frase:
      'Los pies más pequeños hacen las huellas más grandes en nuestros corazones.',
    img: imgPalabrasM,
  },
  {
    key: 2,
    nombre: 'Jennifer',
    cargo: 'Madrina',
    frase:
      'El vínculo entre una madre y su bebé recién nacido es instantáneo y más fuerte que el hierro.',
    img: imgPalabrasM,
  },
  {
    key: 3,
    nombre: 'Arturo',
    cargo: 'Padrino',
    frase:
      'Un bebé recién nacido es el trabajo más noble que dos personas pueden hacer.',
    img: imgPalabrasH,
  },
];
