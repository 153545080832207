import imgServicios1 from '../../../../assets/img/templates/template1/img/index/wedding-gc3a49c512_1920.jpg'
export const Servicios1Template1 = ({styleTemplate1}) => {
  return (
    <>
    <section id={styleTemplate1.features} className={styleTemplate1.features}>
  <div className="container" data-aos="fade-up">

    <div className="row">
      <div className={`${styleTemplate1.image} col-lg-6`} style={{backgroundImage:`url(${imgServicios1})`}}
        data-aos="fade-right"></div>
      <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
        <div className={`${styleTemplate1['icon-box']} mt-5 mt-lg-0`} data-aos="zoom-in" data-aos-delay="150">
          <i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-friends"
              width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
              strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="7" cy="5" r="2" />
              <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
              <circle cx="17" cy="5" r="2" />
              <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
            </svg></i>
          <h4>Código de Vestimenta</h4>
          <p>Formal/Smart Casual</p>
        </div>
        <div className={`${styleTemplate1['icon-box']} mt-5`} data-aos="zoom-in" data-aos-delay="150">
          <i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-gift"
              width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
              strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="3" y="8" width="18" height="4" rx="1" />
              <line x1="12" y1="8" x2="12" y2="21" />
              <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
              <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
            </svg></i>
          <h4>Mesa de regalos</h4>
          <p style={{fontSize:'16px'}}>Da click en el enlace!! <span style={{visibility:'hidden'}}>Regalos</span> <br/><a href="https://google.com">Regalos</a></p>
        </div>
        <div className={`${styleTemplate1['icon-box']} mt-5`} data-aos="zoom-in" data-aos-delay="150">
          <i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-building-castle" width="44" height="44" viewBox="0 0 24 24"
              strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M15 19v-2a3 3 0 0 0 -6 0v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14h4v3h3v-3h4v3h3v-3h4v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
              <line x1="3" y1="11" x2="21" y2="11" />
            </svg></i>
          <h4>Hospedaje</h4>
          <p style={{fontSize:'16px'}}>Da click en el enlace!!<br/><a href="https://google.com">Hospedajes Recomendados</a></p>
        </div>
        <div className={`${styleTemplate1['icon-box']} mt-5`} data-aos="zoom-in" data-aos-delay="150">
          <i className="bx "><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mood-kid"
              width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
              strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="9" y1="10" x2="9.01" y2="10" />
              <line x1="15" y1="10" x2="15.01" y2="10" />
              <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
              <path d="M12 3a2 2 0 0 0 0 4" />
            </svg></i>
          <h4>Niños no</h4>
          <p>Por las actividades del evento la entrada de niños menores de 12 años esta prohibida</p>
        </div>
      </div>
    </div>

  </div>
</section>
    </>
  )
}
