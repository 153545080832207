import React from 'react'
import { Link } from 'react-router-dom';
import Paquetes from '../../Home/components/Paquetes';
export const ProcesoCompra = () => {
  return (
   <div className="container-fluid card bg-dark text-white">
        <div style={{height: 100}}></div>
        <div className="row">
            <div className="col text-center">
                <p className="invitacion-text" style={{fontSize:'40px', fontWeight:'bolder'}}>Proceso de pago y compra.</p>
            </div>
        </div>

        <div className="row justify-content-around p-2">
            <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="card shadow text-center text-white bg-dark border-light" data-aos="zoom-in-down" data-aos-duration="3000">
  <div className="card-header">
    <p className="text-title-pack">Métodos de pago (próximamente Mercado Pago y Paypal)</p>
  </div>
  <div className="card-body">
    <div className="row justify-content-center">
      <div className="col">
        <div>
          <p className="font-weight-bold h4">Transferencia o Depósito</p>
          <p className="font-weight-bold h4">CLABE interbancaria</p>
          <p className="h5 text-muted">0585 9700 0011 0759 74</p>
        </div>
      </div>
    </div>
  </div>
</div>
            </div>
        </div>

        <div className="row text-center">
            <div className="col align-self-center text-justify">
                <p className="text-title-pack">Pasos</p>
                <ol style={{listStylePosition:'inside'}}>
                    <li>Elige un estilo de invitación.</li>
                    <li>Ponte en contacto con nosotros <a href="https://api.whatsapp.com/send?phone=5638481998" rel="noopener noreferrer" target="_blank">(Haz click aquí!!)</a>.</li>
                    <li>Espera a que nos contactemos contigo (Dentro de un periodo de 24hrs).</li>
                    <li>Realiza el pago como lo prefieras.</li>
                    <li>Envía el comprobante/voucher en la conversación que tenemos contigo.</li>
                    <li>Después de confirmar el pago y que nos hayas dado todos tus requerimientos e información para tu
                        evento,
                        la invitación estará lista en 4 días hábiles.
                    </li>
                    <li>Tu invitación estará lista para compartir con tus seres queridos!!!</li>
                </ol>
                <hr/>
                <p>Toda la información, fotos y videos necesarios deben ser enviados para que podamos empezar a realizar
                    la invitación.</p>
                <p>No se inicia la creación de la invitación si el cliente no confirma toda la información necesaria.
                </p>
                <p>Cualquier cambio puede ser realizado, deberá ser comunicado dentro del periodo de desarrollo y tomará
                    1 día más al total del tiempo para termino de la invitación</p>
                <p>Si alguna modificación es requerida después de la entrega de la invitación este tendrá un cargo extra
                    dependiendo del tipo de modificación.</p>
            </div>
        </div>
        <div className="row justify-content-evenly p-3 proceso-compra">
            <Paquetes />
        </div>



</div>

  )
}
