

export const FooterTemplate5 = ({styleTemplate5}) => {
  return (
       <footer id={styleTemplate5.footer}>
    <div className={`container ${styleTemplate5.container2}`}>
      <h3>Click Invitaciones</h3>
      <div className="social-links">
        <a href="#" className="facebook"><i className="bi-facebook"></i></a>
        <a href="#" className="instagram"><i className="bi-instagram"></i></a>
      </div>
      <div className="copyright">
        &copy; Copyright <strong><span>Click Invitaciones</span></strong>.Todos los derechos reservados
      </div>
    </div>
  </footer>
  )
}
