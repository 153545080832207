
import imgAlbum1 from  '../../../../assets/img/templates/template4/album/pexels-craig-adderley-2306281.jpg'
import imgAlbum2 from  '../../../../assets/img/templates/template4/album/pexels-asad-photo-maldives-169198.jpg'
import imgAlbum3 from  '../../../../assets/img/templates/template4/album/pexels-andrea-piacquadio-787961.jpg'
import imgAlbum4 from  '../../../../assets/img/templates/template4/album/pexels-luis-quintero-2774556.jpg'
import imgAlbum5 from  '../../../../assets/img/templates/template4/album/pexels-abby-kihano-431722.jpg'
import imgAlbum6 from  '../../../../assets/img/templates/template4/album/pexels-ivan-57980.jpg'
import imgAlbum7 from  '../../../../assets/img/templates/template4/album/pexels-josh-sorenson-976866.jpg'
import imgAlbum8 from  '../../../../assets/img/templates/template4/album/pexels-teddy-yang-2263436.jpg'
import imgAlbum9 from  '../../../../assets/img/templates/template4/album/pexels-wolfgang-2747446.jpg'
import { useIsotopeReact } from '../../../Comun/Hooks/useIsotopeReact'
export const AlbumTemplate4 = ({styleTemplate4}) => {
   const {handleFilterKeyChange,activeFilter} = useIsotopeReact();
   
  return (
    <section id="portfolio">
      <div className="container" data-aos="fade-up">

        <div >
          <h2 className={styleTemplate4.titleAlbum}>Álbum fotográfico</h2>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id={styleTemplate4.albumFilters}>
              <li onClick={handleFilterKeyChange('*',0)} className={`${activeFilter===0 ? styleTemplate4.albumFilterActive : "" } ${styleTemplate4.albumFilterItem}`}>Todas</li>
              <li onClick={handleFilterKeyChange('filter-salon',1)} className={`${activeFilter===1 ? styleTemplate4.albumFilterActive : "" } ${styleTemplate4.albumFilterItem}`}>Salon</li>
              <li onClick={handleFilterKeyChange('filter-evento',2)} className={`${activeFilter===2 ? styleTemplate4.albumFilterActive : "" } ${styleTemplate4.albumFilterItem}`}>Evento</li>
              <li onClick={handleFilterKeyChange('filter-lugar',3)} className={`${activeFilter===3 ? styleTemplate4.albumFilterActive : "" } ${styleTemplate4.albumFilterItem}`}>Lugar</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container gy-4" data-aos="fade-up" data-aos-delay="200">

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-salon">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum1} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-lugar">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum2} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-salon">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum3} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-evento">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum4} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-lugar">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum5} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-salon">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum6} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-evento">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum7} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-evento">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum8} className={`img-fluid`} alt=""/>
            </div>
          </div>

          <div className="col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item filter-lugar">
            <div className={`portfolio-wrap`}>
              <img src={imgAlbum9} className={`img-fluid`} alt=""/>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}
