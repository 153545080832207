import AOS from "aos";
import { useRef, useEffect } from "react";
import Spacer from "../../Comun/Componentes/Spacer";


import "./Nosotros.css";

const NosotrosModule = ({ animation, setCardHeight }) => {

  const ref = useRef(null);

  useEffect(() => {
    AOS.init();
    setCardHeight(ref.current.clientHeight);
  });

  return (
    <>
      {/*
        <div className="btn-whatsapp">
          <a
            href="https://api.whatsapp.com/send?phone=5638481998"
            target="_blank"
          >
            <img
              className="btn-whats"
              src="assets/img/boton-whatsapp.png"
              alt="whatsapp"
            />
          </a>
        </div> */}
      <div ref={ref} className="container-fluid nosotros-padding">
        <div
          className="card shadow"
          data-aos={animation}
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <div className="row px-2">
            <p className="title-nosotros">Click Invitaciones</p>
          </div>
          <div className="row px-2">
            <div className="col-xs-6 col-md col-lg">
              <p className="text-nosotros">
                Somos una empresa de desarrollo tecnológico y uno de nuestros
                sectores son las invitaciones digitales para todo tipo de
                eventos. Tenemos gran experiencia en tecnología, brindando un
                servicio de excelencia.
              </p>
            </div>
            <div className="col-xs-6 col-md col-lg">
              <div className="row justify-content-end">
                <div className="col-2 align-self-center">
                  <div className="icono-index" style={{height:'50px', width: '50px', borderRadius: '50px', backgroundColor: 'orange'}}>
                    <svg style={{marginLeft:'2.5px', marginTop:'2px'}}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-heart"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                    </svg>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <p className="text-icono-caja">Responsabilidad</p>
                </div>
                <div className="col-2 align-self-center">
                  <div className="icono-index" style={{height:'50px', width: '50px', borderRadius: '50px', backgroundColor: 'orange'}}>
                    <svg
                      style={{marginLeft:'4px'}}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-plane-departure"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M15 12h5a2 2 0 0 1 0 4h-15l-3 -6h3l2 2h3l-2 -7h3z"
                        transform="rotate(-15 12 12) translate(0 -1)"
                      />
                      <line x1="3" y1="21" x2="21" y2="21" />
                    </svg>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <p className="text-icono-caja">Rapidez</p>
                </div>
              </div>
              <Spacer space={20} />
              <div className="row justify-content-end">
                <div className="col-2 justify-content-end">
                  <div className="icono-index" style={{height:'50px', width: '50px', borderRadius: '50px', backgroundColor: 'orange'}}>
                    <svg
                    style={{marginLeft:'3px', marginTop: '4px'}}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-device-desktop-analytics"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="3" y="4" width="18" height="12" rx="1" />
                      <path d="M7 20h10" />
                      <path d="M9 16v4" />
                      <path d="M15 16v4" />
                      <path d="M9 12v-4" />
                      <path d="M12 12v-1" />
                      <path d="M15 12v-2" />
                      <path d="M12 12v-1" />
                    </svg>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <p className="text-icono-caja">Tecnología</p>
                </div>
                <div className="col-2 align-self-center">
                  <div className="icono-index" style={{height:'50px', width: '50px', borderRadius: '50px', backgroundColor: 'orange'}}>
                    <svg
                    style={{marginLeft:'4px', marginTop:'2px'}}
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-refresh"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                      <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                    </svg>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  <p className="text-icono-caja">Vanguardia</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row-extended nosotros-color-sub mt-3">
            <div className="col-md px-2">
              <p className="nosotros-text-sub">¿Por qué escogernos?</p>
              <p className="nosotros-text-sub">
                Tenemos una gran experiencia en el desarrollo tecnológico con
                responsabilidad para nuestros clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NosotrosModule;
