import slider1 from '../../../assets/img/damas.jpg';
import slider2 from '../../../assets/img/pexels-tembela-bohle-1114425.jpg';

export const cardBlogInfo = [
  {
    key: 1,
    img: slider1,
    alt: 'Boda de ensueño',
    title: 'Boda de ensueño: Guía completa para organizar tu día especial.',
    subTitle:
      'Planificar una boda puede parecer abrumador, pero con la guía adecuada, puede ser una experiencia emocionante y divertida.',
    link: '/planifica-tu-boda',
  },
  {
    key: 2,
    img: slider2,
    alt: 'Luna de miel',
    title:
      'Consejos para hacer de tu luna de miel una experiencia inolvidable.',
    subTitle:
      'El artículo ofrece consejos para planear una luna de miel inolvidable, incluyendo elegir el destino, hacer reservaciones y crear un itinerario.',
    link: '/luna-de-miel',
  },
];
