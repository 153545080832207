
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates";

export const HeaderTemplate5 = ({styleTemplate5,setCardHeight}) => {
  const ref = useRef(null);

  useEffect(() => {
    setCardHeight(ref.current.clientHeight);
  });

  return (
    <header ref={ref} id="header" className={`fixed-top ${styleTemplate5.header}`}>
  <div className={`container ${styleTemplate5.container2} d-flex align-items-center justify-content-between`}>

    <h1 className={styleTemplate5.logo}><Link style={{textDecoration: 'none'}} to={'/Invitaciones'}>Click Invitaciones</Link></h1>
    <nav id="navbar" className={styleTemplate5.navbar}>
      <ul>
        <li><a style={{textDecoration: 'none'}} className="active" href="#hero">Inicio</a></li>
        <li><a style={{textDecoration: 'none'}} href="#about">Nosotros</a></li>
        <li><a style={{textDecoration: 'none'}} href="#services">Servicios</a></li>
        <li><a style={{textDecoration: 'none'}} href="#portfolio">Album de fotos</a></li>
        <li><a style={{textDecoration: 'none'}} href="#contact">Confirmación y Dirección</a></li>
      </ul>
      <MenuTemplates template={1} color='black'/>
    </nav>
  </div>
</header>
  )
}
