import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import {
  AlbumTemplate6,
  DataTemplate6,
  DireccionConfirmacionTemplate6,
  FooterTemplate6,
  HeaderTemplate6,
  HeroTemplate6,
  MensajeTemplate6,
  MusicTemplate6,
  NombramientoTemplate6,
  NosotrosTemplate6,
  PalabrasTemplate6,
  ServiciosTemplate6,
} from "./components";
import styleTemplate6 from "./css/template6.module.css";
import useWindowsSize from "../../Comun/Hooks/useWindowsSize";
import { FireworksComp } from "../../Comun/Componentes/FireworksComp";
export const Invitacion6 = () => {
  let dateEvent = dayjs("2023-10-22");
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { innerHeight } = useWindowsSize();
  const [cardHeight, setCardHeight] = useState();

  let size = Math.floor(innerHeight);

  return (
    <>
      <HeaderTemplate6 styleTemplate6={styleTemplate6} />
      <HeroTemplate6 styleTemplate6={styleTemplate6} size={size} />
      {/* <FireworksComp/> */}
      <MusicTemplate6 styleTemplate6={styleTemplate6} />
      <DataTemplate6 styleTemplate6={styleTemplate6} dateEvent={dateEvent} />
      <NosotrosTemplate6 styleTemplate6={styleTemplate6} />
      <MensajeTemplate6 styleTemplate6={styleTemplate6} />
      <ServiciosTemplate6 styleTemplate6={styleTemplate6} />
      <AlbumTemplate6 styleTemplate6={styleTemplate6} />
      <PalabrasTemplate6 styleTemplate6={styleTemplate6} />
      <NombramientoTemplate6 styleTemplate6={styleTemplate6} />
      <DireccionConfirmacionTemplate6 styleTemplate6={styleTemplate6} />
      <FooterTemplate6 styleTemplate6={styleTemplate6} />
    </>
  );
};
