import { useFormTemplates } from "../../../Comun/Hooks/useFormTemplates";


export const ConfirmacionUbicacion = ({styleTemplate5}) => {
  const {onInputChange, onSumit, nombre, confirmacion} = useFormTemplates({
    nombre:'', confirmacion: ''
  });
  return (
    <section id="contact" className={styleTemplate5['contact-us']}>
    <div className={`container ${styleTemplate5.container2}`}>
    <div className={styleTemplate5['section-title']}>
      <h2>Ubicación y confirmación</h2>
      <p>Aquí encontraras los detalles de la ubicación y puedes confirmarnos que nos acompañaras en este dia tan especial, te esperamos.</p>
    </div>
    <div>
      <iframe style={{border:'0', width:'100%', height:'270px'}}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.2398736272694!2d-99.12612908604444!3d19.48831303184559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f9a20cc7d7df%3A0xf7aa6c7c6d8240fa!2sParroquia%20del%20Se%C3%B1or%20de%20la%20Misericordia!5e0!3m2!1ses-419!2smx!4v1667879441638!5m2!1ses-419!2smx"
        frameBorder="0" allowFullScreen title="ubicacion"></iframe>
    </div>

    <div className="row mt-5">

      <div className="col-lg-4">
        <div className={styleTemplate5.info}>

          <div className={styleTemplate5.email}>
            <i className={styleTemplate5.bi}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2" width="44"
                height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round"
                strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="18" y1="6" x2="18" y2="6.01" />
                <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                <line x1="9" y1="4" x2="9" y2="17" />
                <line x1="15" y1="15" x2="15" y2="20" />
              </svg></i>
            <h4>Salón:</h4>
            <p>Av. Insurgentes Nte., Lindavista, Gustavo A. Madero, 07300 Ciudad de México, CDMX</p>
          </div>

        {/* <div className="phone">
            <i className="bi bi-phone"></i>
            <h4>Call:</h4>
            <p>+1 5589 55488 55s</p>
          </div> */}

        </div>

      </div>

      <div className="col-lg-8 mt-5 mt-lg-0">

        <form id="form-demo" onSubmit={onSumit} className={styleTemplate5['php-email-form']}>
          <div className="row">
            <div className={`col-md-12 form-group ${styleTemplate5['form-group']}`}>
              <input type="text" name="nombre" className="form-control" id="name" placeholder="Nombre" required onChange={onInputChange} value={nombre}/>
              <div className="validate"></div>
            </div>
          </div>
          <div className={`form-group ${styleTemplate5['form-group']} mt-3`}>
            <textarea className="form-control" name="confirmacion" rows="5" placeholder="Mensaje" required onChange={onInputChange} value={confirmacion}></textarea>
            <div className="validate"></div>
          </div>
          <div className="text-center"><button type="submit">Enviar confirmación</button></div>
        </form>

      </div>

    </div>

  </div>
</section>
  )
}
