import { useSliderSlike } from "../../../Comun/Hooks/useSliderSlike";
import { palabrasTemplate6 } from "../js/palabras-template6"


export const PalabrasTemplate6 = ({styleTemplate6}) => {
  const {Slider, settings}=useSliderSlike(3);
  return (
    <section id="testimonials" className={styleTemplate6.testimonials}>
  <div className="container">

    <div className={styleTemplate6['section-title']} data-aos="zoom-out">
      <h2>Click invitaciones</h2>
      <p>Palabras de los padrinos</p>
    </div>

    <div className={`${styleTemplate6['testimonials-slider']} swiper`} data-aos="fade-up" data-aos-delay="100">
      <div className="swiper-wrapper">
        <Slider {...settings}>

{palabrasTemplate6.map(palabra=>(<div key={palabra.key} className="swiper-slide">
          <div className={`${styleTemplate6['testimonial-item']} pb-5`}>
            <p>
              <i className={`bx bxs-quote-alt-left ${styleTemplate6['quote-icon-left']}`}></i>
             {palabra.text}
              <i className={`bx bxs-quote-alt-right ${styleTemplate6['quote-icon-right']}`}></i>
            </p>
            <img src={palabra.img}
              className={styleTemplate6['testimonial-img']} alt=""/>
            <h3>{palabra.name}</h3>
            <h4>{palabra.position}</h4>
          </div>
        </div>))}
        </Slider>
        

      </div>
      <div className="swiper-pagination"></div>
    </div>

  </div>
</section>
  )
}
