import { useForm } from "../Hooks/useForm";
import { Link } from "react-router-dom";
import "./css/contactanos.css";
import { useRef, useEffect } from "react";

const Formulario = ({ setCardHeight = null }) => {
  useEffect(() => {
    if (setCardHeight != null) {
      setCardHeight(ref.current.clientHeight);
    }
  });
  const ref = useRef(null);
  const {
    ClickPrivacidad,
    isChecked,
    handleOnChange,
    onSumit,
    onInputChange,
    nombre,
    whatsApp,
    correo,
    asunto,
  } = useForm({ nombre: "", whatsApp: "", correo: "", asunto: "" });

  return (
    <div ref={ref} className="container-fluid"  data-aos="zoom-in"
            data-aos-duration="2500">
      <div className="row">
        <div className="col-md-2"></div>
        <div id="quitar" className="col-md-8 abajo">
          <div
            className="row color-contacto shadow caja-b p-3"
          >
            <p className="texto-contactanos">
              Si necesitas un desarrollo a tu media somos los mejores ¡Contáctenos para más información!
            </p>
            <form onSubmit={onSumit} id="formulario">
              <div className="col-xs col-md col-lg">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control color-input"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    placeholder="Nombre"
                    name="nombre"
                    id="nombre"
                    onChange={onInputChange}
                    value={nombre}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="tel"
                    className="form-control color-input"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    placeholder="WhatsApp"
                    name="whatsApp"
                    id="whats"
                    onChange={onInputChange}
                    value={whatsApp}
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control color-input"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    placeholder="Correo Electrónico"
                    name="correo"
                    id="email"
                    onChange={onInputChange}
                    value={correo}
                  />
                </div>
              </div>
              <div className="col-xs col-md col-lg">
                <div className="input-group mb-3">
                  <textarea
                    type="text"
                    className="form-control color-input"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    placeholder="Asunto"
                    rows="3"
                    name="asunto"
                    id="asunto"
                    onChange={onInputChange}
                    value={asunto}
                    required
                  ></textarea>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={isChecked}
                    onChange={handleOnChange}
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    <Link className="nav-link" to="/aviso-privacidad">
                      Aceptar aviso de privacidad
                    </Link>
                  </label>
                </div>
                <div className="row">
                  <div className="col text-end" onClick={ClickPrivacidad}>
                    <button
                      className={`btn ${
                        !isChecked ? "disabled" : ""
                      } boton-contactanos btn-primary`}
                      type="submit"
                      id="contact"
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default Formulario;
