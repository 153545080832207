import Paquetes1 from "../../Comun/Componentes/Paquete1";
import Paquetes2 from "../../Comun/Componentes/Paquete2";
const Paquetes = () => {
  return (
    <div className="container-fluid p-3">
      <div className="row justify-content-around">
        <Paquetes1 />
        <Paquetes2 />
      </div>
    </div>
  );
};

export default Paquetes;
