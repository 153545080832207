import styleTemplate2 from './css/template2.module.css'
import { AlbumTemplete2, DateTemplate2, DireccionConfirmacionTemplte2, FooterTemplate2, HeaderTemplate2, HeroTemplate2, NombramientoTemplete2, NosotrosTemplate2, PalabrasTemplate2, ServiciosTemplate2 } from './components'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { FireworksComp } from '../../Comun/Componentes/FireworksComp';
export const Invitacion2 = () => {
   let dateEvent = dayjs('2023-09-22');
  useEffect(() => {
    document.title = "Invitacón 2 | Click Invitaciones"
       Aos.init({duration:2000});
    }, []);
  return (
    <>
<HeaderTemplate2 styleTemplate2={styleTemplate2}/>
{/* <FireworksComp /> */}
<HeroTemplate2 styleTemplate2={styleTemplate2}/>
<NosotrosTemplate2 styleTemplate2={styleTemplate2}/>
<DateTemplate2 styleTemplate2={styleTemplate2} dateEvent={dateEvent}/>
<ServiciosTemplate2 styleTemplate2={styleTemplate2}/>
<AlbumTemplete2 styleTemplate2={styleTemplate2}/>
<PalabrasTemplate2 styleTemplate2={styleTemplate2}/>
<NombramientoTemplete2 styleTemplate2={styleTemplate2}/>
<DireccionConfirmacionTemplte2 styleTemplate2={styleTemplate2}/>
<FooterTemplate2 styleTemplate2={styleTemplate2}/>
    </>
  )
}
