import imganeAlbum1 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-1.jpg';
import imganeAlbum2 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-3.jpg';
import imganeAlbum3 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-4.jpg';
import imganeAlbum4 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-5.jpg';
import imganeAlbum5 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-6.jpg';
import imganeAlbum6 from '../../../../assets/img/templates/template3/img/index/portfolio/portfolio-2.jpg';

export const albumTemplte3 = [
  { key: 0, img: imganeAlbum1, alt: '', filter: 'filter-puente' },
  { key: 2, img: imganeAlbum2, alt: '', filter: 'filter-puente' },
  { key: 3, img: imganeAlbum3, alt: '', filter: 'filter-lago' },
  { key: 4, img: imganeAlbum4, alt: '', filter: 'filter-vestido' },
  { key: 5, img: imganeAlbum5, alt: '', filter: 'filter-lago' },
  { key: 6, img: imganeAlbum6, alt: '', filter: 'filter-vestido' },
];
