import musica from '../../../../assets/music/templates/MAGIC-Rude.mp3'

export const MusicTemplate1 = () => {
    
    
  return (
   <>
   <div style={{height: 50}} ></div>
    <div className="container" data-aos="fade-up">

    <div className="row">
        <div className="col" style={{textAlign:'center'}}>
            <audio id="my-audio" autoPlay controls loop="loop">
                <source src={musica}/>
                Navegador no compatible
            </audio>

        </div>
    </div>

</div>
<div style={{height: 50}}></div>
   </>
  )
}
