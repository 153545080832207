import styleTemplate1 from './css/template1.module.css'
import { AlbumTemplate1, ContactoUbicacionTemplate1, DateTemplate1, FooterTemplate1, HeaderTamplete1, HeroTemplate1, MensajeTemplate1, MusicTemplate1, NombramientosTemplate1, NosotrosTemplate1, PalabrasTemplate1, Servicios1Template1, Servicios2Tempate1 } from "./components"
import Aos from 'aos';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useWindowsSize from '../../Comun/Hooks/useWindowsSize';
export const Invitacion1 = () => {
  let dateEvent = dayjs('2023-11-22');
useEffect(() => {
    document.title = 'Invitación 1 | Click Invitaciones'
       Aos.init({duration:2000});
    }, []);
  
    const { innerHeight } = useWindowsSize();
    const [cardHeight, setCardHeight] = useState();
  
    // let size = Math.floor(innerHeight) - cardHeight;
    let size = Math.floor(innerHeight)
  return (
    <>
    <HeaderTamplete1 styleTemplate1={styleTemplate1} setCardHeight={setCardHeight}/>
    <HeroTemplate1 styleTemplate1={styleTemplate1} size={size}/>
    <MusicTemplate1 styleTemplate1={styleTemplate1}/>
    <DateTemplate1 styleTemplate1={styleTemplate1} dateEvent={dateEvent}/>
    <NosotrosTemplate1 styleTemplate1={styleTemplate1}/>
    <Servicios1Template1 styleTemplate1={styleTemplate1}/>
    <Servicios2Tempate1 styleTemplate1={styleTemplate1}/>
    <MensajeTemplate1 styleTemplate1={styleTemplate1}/>
    <AlbumTemplate1 styleTemplate1={styleTemplate1}/>
    <PalabrasTemplate1 styleTemplate1={styleTemplate1}/>
    <NombramientosTemplate1 styleTemplate1={styleTemplate1}/>
    <ContactoUbicacionTemplate1 styleTemplate1={styleTemplate1}/>
    <FooterTemplate1 styleTemplate1={styleTemplate1}/>
    </>
  )
}
