import imagenM from '../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg';
import imagenH from '../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg';

export const nombramientos = [
  {
    nombre: 'Luis',
    cargo: 'Padrino',
    img: imagenH,
    key: '0',
  },
  {
    nombre: 'Victoria',
    cargo: 'Madrina',
    img: imagenM,
    key: '1',
  },
  {
    nombre: 'Angel',
    cargo: 'Padrino',
    img: imagenH,
    key: '2',
  },
  {
    nombre: 'Luisa',
    cargo: 'Madrina',
    img: imagenM,
    key: '3',
  },
];
