import { useEffect } from 'react';
import Aos from 'aos';
import Formulario from '../Comun/Componentes/Formulario';
import Spacer from '../Comun/Componentes/Spacer';
import Carrusel from './components/Carrusel';
import Leaf from './components/Leaf';
import Paquetes from './components/Paquetes';
import Slider from './components/Slider';
import useWindowsSize from '../Comun/Hooks/useWindowsSize';
// import { getMessaging, getToken } from 'firebase/messaging';
import { Helmet } from 'react-helmet';
import { AdsComponent } from '../adsense/AdsComponent ';
const Home = () => {
  // const messaging = getMessaging();
  // Add the public key generated from the console here.
  // const notificacion = async () => {
  //   const token = await getToken(messaging, {
  //     vapidKey:
  //       'BN6817jt0tMEUbC9s9D8RehYEL4mNd7F-s2h8Fop4EEcxTsv01So61tCNsoc86HKoZJuEKDzVFjgbQi_gXzb5nw',
  //   });
  // };
  useEffect(() => {
    // notificacion();
    Aos.init();
  }, []);

  const { innerHeight } = useWindowsSize();

  let size = Math.floor(innerHeight) - 56;

  return (
    <>
      <Helmet>
        <title>Click Invitaciones</title>
        <meta
          content="Invitaciones completamente digitales para todo tipo de eventos al alcance de un clic"
          name="description"
        ></meta>
        <meta
          content="invitaciones, invitaciones digitales, eventos, bodas, invitaciones bodas, invitaciones bautizo, invitaciones eventos"
          name="keywords"
        ></meta>
      </Helmet>
      <Spacer space={56} />
      <Slider size={size} />
      <Leaf />
      <Paquetes />
      <div className="container-fluid">
<div className="row justify-content-around">
        <div className="col-12 col-md-6">
          <AdsComponent />
        </div>
        <div className="col-12 col-md-6">
          <AdsComponent />
        </div>
      </div>
      </div>
      <Carrusel />
      <Spacer space={30} />
      <Formulario />
      <Spacer space={40} />
    </>
  );
};

export default Home;
