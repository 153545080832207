import { useFormTemplates } from "../../../Comun/Hooks/useFormTemplates";

export const ContactoUbicacionTemplate4 = ({ styleTemplate4 }) => {
  const { onInputChange, onSumit, nombre, confirmacion } = useFormTemplates({
    nombre: "",
    confirmacion: "",
  });
  return (
    <>
      <div id="contact">
        <div className="container" data-aos="fade-up">
          <div>
            <h2 className={styleTemplate4.locationTitle}>
              Ubicación y confirmación
            </h2>
          </div>

          <div>
            <iframe
              style={{ border: "0", width: "100%", height: "270px" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.4606378175235!2d-99.14082408453447!3d19.435696745616674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f92cf35a3185%3A0x3b3df77e7b47055d!2sPalacio%20Metropolitano!5e0!3m2!1ses-419!2smx!4v1670816319252!5m2!1ses-419!2smx"
              frameborder="0"
              allowfullscreen
              title="mapa"
            ></iframe>
          </div>
          <div style={{ height: 50 }}></div>
          <div className="row">
            <div className="col-lg-4">
              <div className={styleTemplate4.contactInfo}>
                <div className={`address row align-items-center `}>
                  <div className="col-auto">
                    <i className="bi">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-location"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                      </svg>
                    </i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h4 className={styleTemplate4.locationSubtitle}>
                        Organización Nacional De Profesores Unidos
                      </h4>

                      <p className={styleTemplate4.locationSubtitleText}>
                        Teléfono: 55-555-5555
                      </p>
                      <p className={styleTemplate4.locationSubtitleText}>
                        Teléfono: 55-555-5555
                      </p>
                    </div>
                  </div>
                </div>

                <div className={`address row align-items-center mt-5 `}>
                  <div className="col-auto">
                    <i className="bi">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-map-2"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="18" y1="6" x2="18" y2="6.01" />
                        <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                        <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                        <line x1="9" y1="4" x2="9" y2="17" />
                        <line x1="15" y1="15" x2="15" y2="20" />
                      </svg>
                    </i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h4 className={styleTemplate4.locationSubtitle}>
                        Preventa de Boletos:
                      </h4>
                      <p className={styleTemplate4.locationSubtitleText}>
                        Av. Insurgentes Nte., Lindavista, Gustavo A. Madero,
                        07300 Ciudad de México, CDMX
                      </p>
                    </div>
                  </div>
                </div>

                <div className={`address row align-items-center mt-5`}>
                  <div className="col-auto">
                    <i className="bi">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-map-2"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="18" y1="6" x2="18" y2="6.01" />
                        <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                        <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                        <line x1="9" y1="4" x2="9" y2="17" />
                        <line x1="15" y1="15" x2="15" y2="20" />
                      </svg>
                    </i>
                  </div>
                  <div className="col">
                    <div className="row">
                      <h4 className={styleTemplate4.locationSubtitle}>
                        Lugar de la Conferencia:
                      </h4>
                      <p className={styleTemplate4.locationSubtitleText}>
                        Av. Insurgentes Nte., Lindavista, Gustavo A. Madero,
                        07300 Ciudad de México, CDMX
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form
                id="formulario"
                onSubmit={onSumit}
                className={styleTemplate4["php-email-form"]}
              >
                <div className="row">
                  <div className="col-md-12 form-group">
                    <input
                      type="text"
                      name="nombre"
                      value={nombre}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Nombre"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    onChange={onInputChange}
                    name="confirmacion"
                    value={confirmacion}
                    rows="5"
                    placeholder="Mensaje a los organizadores"
                    required
                  ></textarea>
                </div>
                <div className="text-center mt-2">
                  <button
                    type="submit"
                    className={styleTemplate4.contactoBoton}
                  >
                    Enviar Confirmación
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
