

export const HeroTemplte3 = ({styleTemplate3,size}) => {
  return (
   <section id="hero" className={`${styleTemplate3.hero} d-flex justify-cntent-center align-items-center`} style={{ objectFit: "cover", height: size }}>
    <div id="heroCarousel" data-bs-interval="5000" className={`${styleTemplate3.container2} container carousel carousel-fade`} data-bs-ride="carousel">
      <div className="carousel-item active">
        <div className={`${styleTemplate3['carousel-container2']} carousel-container`}>
          <h2 className="animate__animated animate__fadeInDown">Sofía Valentina</h2>
          <h3 className={styleTemplate3.heroText}>Mis XV</h3>
        </div>
      </div>
    </div>
  </section>
  )
}
