
import { useEffect } from 'react';
import { ProcesoCompra } from './components/ProcesoCompra'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { AdsComponent } from '../adsense/AdsComponent ';

export const PreguntasFrecuentes = () => {
    useEffect(() => {
       Aos.init({duration:2000});
    }, []);
  return (
    <>
    <Helmet>
        <title>Preguntas frecuentes | Click Invitaciones</title>
        <meta
          content="Invitaciones completamente digitales para todo tipo de eventos al alcance de un clic"
          name="description"
        ></meta>
        <meta
          content="invitaciones, invitaciones digitales, eventos, bodas, invitaciones bodas, invitaciones bautizo, invitaciones eventos"
          name="keywords"
        ></meta>
      </Helmet>
    <ProcesoCompra/>
        <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
      </div>

    </>
  )
}
