
import { useIsotopeReact } from '../../../Comun/Hooks/useIsotopeReact'

import { albumTemplate2 } from '../js/album-template2';



export const AlbumTemplete2 = ({styleTemplate2}) => {
   const {handleFilterKeyChange, activeFilter} = useIsotopeReact();

  return (
    <section id="portfolio" className={styleTemplate2.portfolio}>
      <div className="container" data-aos="fade-up">

        <div className={styleTemplate2['section-title']}>
          <h2>Álbum Fotográfico</h2>
          <p>Cada bebé recién nacido es un tipo diferente de flor y todos juntos hacen de este mundo un jardín hermoso.</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id={styleTemplate2['portfolio-flters']}>
              <li onClick={handleFilterKeyChange('*', 0)} className={`${activeFilter===0 ? styleTemplate2['filter-active'] : "" } ${styleTemplate2.albumFilterItem}`} >Todas</li>
              <li onClick={handleFilterKeyChange('filter-familia', 1)} className={`${activeFilter===1 ? styleTemplate2['filter-active'] : "" } ${styleTemplate2.albumFilterItem}`} >Familia</li>
              <li onClick={handleFilterKeyChange('filter-bautizo', 2)} className={`${activeFilter===2 ? styleTemplate2['filter-active'] : "" } ${styleTemplate2.albumFilterItem}`} >Bautizo</li>
              <li onClick={handleFilterKeyChange('filter-padrinos', 3)} className={`${activeFilter===3 ? styleTemplate2['filter-active'] : "" } ${styleTemplate2.albumFilterItem}`} >Padrinos</li>
            </ul>
          </div>
        </div>

 <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
{albumTemplate2.map(album=>(<div key={album.key} className={`col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item ${album.filter} mt-4`}>
            <div className="portfolio-wrap">
            <a href={album.img}>
              <img src={album.img} className="img-fluid" alt=""/>
            </a>
            </div>
          </div>))}

  </div> 
      </div>
    </section>
  )
}
