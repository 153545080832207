import { templatesData } from "../../Comun/js/templates-data";
import { Link } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

export const CardsTemplates = () => {
  useEffect(() => {
      Aos.init({duration:2000});
      Aos.refresh();
    }, []);
  return (
    <>

    <div className="row" id="card-especialidades">
    
    {templatesData.map((template)=>(
      <div className="col-md-6 espacio-card" key={template.key} data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500"><Link style={{textDecoration:'none'}} to={`/template/${template.url}`}><div className=""><img src={template.img}className="card-img" alt={template.nombre} title={template.nombre}/><div className="tarjeta-especialides-datos text-center shadow-lg"><div className="row"><div className="col"><p className="titulo-card-espe">{template.nombre}</p><p className="text-card">{template.descripcion}</p><p className="leer">Ver Invitación</p></div></div></div></div></Link></div>
    ))}
    
        </div>
    
    </>

  )
}
