import img1Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-1.jpg';
import img2Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-2.jpg';
import img3Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-3.jpg';
import img4Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-4.jpg';
import img5Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-5.jpg';
import img6Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-6.jpg';
import img7Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-7.jpg';
import img8Album from '../../../../assets/img/templates/template5/img/recent-photos/recent-photos-8.jpg';

export const albumPhoto = [
  { img: img1Album, key: 0 },
  { img: img2Album, key: 1 },
  { img: img3Album, key: 2 },
  { img: img4Album, key: 3 },
  { img: img5Album, key: 4 },
  { img: img6Album, key: 5 },
  { img: img7Album, key: 6 },
  { img: img8Album, key: 7 },
];
