import img1Palabras from '../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg';
import img2Palabras from '../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg';

export const palabrasTemplate6 = [
  {
    text: 'Espero que todos los años que compartirán estén llenos de alegría duradera. Que todos los años que compartirán estén llenos de alegría.',
    name: 'Luis',
    position: 'Padrino',
    img: img2Palabras,
    key: 0,
  },
  {
    text: 'Por amor unieron sus vidas en matrimonio y de corazón les deseo todo lo mejor, sean muy felices y disfruten cada día el poder estar juntos.',
    name: 'Ingrid',
    position: 'Madrina',
    img: img1Palabras,
    key: 1,
  },
  {
    text: 'El matrimonio no es una estación de destino, sino un camino que debe seguir. Brindo por su nuevo comienzo, por una larga y feliz vida juntos',
    name: 'Luis',
    position: 'Padrino',
    img: img2Palabras,
    key: 2,
  },
  {
    text: 'Un matrimonio feliz es una larga conversación que siempre parece corta, espero de todo corazón que siempre se amen como este día.',
    name: 'Karina',
    position: 'Madrina',
    img: img1Palabras,
    key: 3,
  },
];
