
import imgNosotros from '../../../../assets/img/templates/template1/img/index/sunset-g9476aabbc_1920.jpg';
export const NosotrosTemplate1 = ({styleTemplate1}) => {
  return (
    <>
    <section id="about" className={styleTemplate1.about}>
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src={imgNosotros} className="img-fluid" alt=""/>
          </div>
          <div className={`col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 ${styleTemplate1.content}`} data-aos="fade-right" data-aos-delay="100">
            <h3>Comó nos conocimos.</h3>
            <p className={styleTemplate1['fst-italic']}>
              Una vez vi a una chica en la escuela. Quise conocerla y me acerqué a ella. Me miró y estando reviso le pregunte su nombre y empezamos a hablar.
            </p>
            <p>
              Al final de la clase decidí acompañarla a su casa y hablamos por varios minutos hasta llegar después de todo eso es historia y hasta el dia de hoy estamos juntos.
            </p>
          </div>
        </div>

      </div>
    </section>
    </>
  )
}
