import { Link } from "react-router-dom";

const Paquetes1 = () => {
  return (
    <div className="col-xs-12 col-md-6 col-lg-5 my-2 ">
      <div className="card shadow p-3" data-aos="fade-up"
          data-aos-duration="2000" style={{ height: "100%" }}>
        <div className="card-body">
        <h5 className="text-center fs-1 fw-bold" style={{color:'black'}}>Paquete Básico</h5>
          <hr style={{color:'black'}} />
          <p style={{color:'black'}}>En esta invitación encontrarás:</p>
          <ul style={{color:'black'}}> 
            <li>
              Invitación completamente responsiva, ve la invitación adaptada a
              distintos dispositivos como: celular, tablet y computadora.
            </li>
            <li>GPS</li>
            <li>Galería de fotos</li>
            <li>Mesa de regalos</li>
            <li>Hospedaje / Itinerario</li>
            <li>Niños admitidos/ no admitidos</li>
            <li>Código de vestimenta</li>
            <li>Sección nombramientos (testigos, padrinos, familia, etc.)</li>
            <li>Sección video</li>
            <li>Invitación activa por 1 mes después del evento.</li>
          </ul>
          <p style={{color:'black'}}>
            Envíos ilimitados, puedes compartir tu invitación cuantas
            veces quieras por WhatsApp o cualquier red social.
          </p>
          <hr style={{color:'black'}} />
          <p style={{color:'black'}} className=" fs-1 fw-bold">MXN $1230.00</p>
          <div className="row justify-content-center">
        <div className="col-6 d-flex justify-content-start">
          <Link to="/preguntas-frecuentes">
          <button className="btn btn-warning">
            Proceso de pago
          </button>
          </Link>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <a href="https://api.whatsapp.com/send?phone=5638481998" rel="noopener noreferrer" target="_blank">
            <button className="btn btn-primary">
              Comprar
            </button>
          </a>
        </div>
      </div>
        </div>
      </div>
    </div>
  );
};

export default Paquetes1;
