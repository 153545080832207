import { useCountDown } from "../../../Comun/Hooks/useCountDown";


export const DataTemplate5 = ({styleTemplate5, dateEvent}) => {
    const {days, hours, minutes, seconds} = useCountDown(dateEvent);

    let daysS = days.split(" ");
  let hoursS = hours.split(" ");
  let minutesS = minutes.split(" ");
  let secondsS = seconds.split(" ");

  return (
   <>
   <div style={{height: '50px'}}></div>
    <div className={`container ${styleTemplate5.container2}`} data-aos="fade-up" data-aos-anchor-placement="center-bottom">
    <div className={styleTemplate5['section-title']}>
        <h2>El evento más importante del año</h2>
        <p className="fs-3">¡Ya viene la cigüeña! Te invitamos a nuestro Baby Shower para celebrarlo</p>
    </div>


    <div className="row">
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div>
              <p
                className="text-center fw-bolder fs-2"
                style={{ color: " #151515" }}
                id="dias"
              >
                {daysS[0]}
                <br />
                {daysS[1]}
              </p>
            </div>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="horas"
            >
              {hoursS[0]}
              <br />
              {hoursS[1]}
            </p>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="minutos"
            >
              {minutesS[0]}
              <br />
              {minutesS[1]}
            </p>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="segundos"
            >
              {secondsS[0]}
              <br />
              {secondsS[1]}
            </p>
          </div>
        </div>
</div>
   
   </>
  )
}
