import { Link } from "react-router-dom";

export const FooterTemplate4 = ({ styleTemplate4 }) => {
  return (
    <>
      <footer id={styleTemplate4.footer}>
        <div className="container">
          <div className={styleTemplate4.copyright}>
            &copy; Copyright{" "}
            <strong>
              <span>
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={"/"}
                >
                  Click Invitaciones
                </Link>
              </span>
            </strong>
            . Todos los derechos reservados.{" "}
            <strong>
              <span>
                {" "}
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={"/aviso-privacidad"}
                >
                  Aviso de Privacidad
                </Link>
              </span>
            </strong>
          </div>
        </div>
      </footer>
    </>
  );
};
