const useSliderBootstrap = (
  styleTemplate4,
  id,
  images,
  sizeCarrusel,
  indicatorsActive = true,
  controlsActive = true
) => {
  let carrouselItems = [];
  let buttons = [];

  const slideText = {
    0: {
      title: "Convención Nacional De Profesores",
      subtitle: '"Enseñar es dejar una huella en la vida de una persona"',
    },
    1: {
      title: "Invitados Especiales",
      subtitle: `Contaremos con la presencia de la maestra: Yoloxóchitl Bustamante
      Díaz, la secretaria de educación Delfina Gómiz Álvarez y el equipo
      de coaching de plataformas digitales SISTEMA DOS.`,
    },
    2: {
      title: "Convención Nacional De Profesores",
      subtitle: '"Enseñar es dejar una huella en la vida de una persona"',
    },
  };
  images.forEach((image, index) => {
    if (index === 0) {
      buttons.push(
        <button
          type="button"
          data-bs-target={`#${id}`}
          data-bs-slide-to={index}
          className={`active ${styleTemplate4.redondeado}`}
          aria-current="true"
          aria-label={`Slide ${index + 1}`}
        />
      );
      carrouselItems.push(
        <div className={`carousel-item active`}>
          <div
            className={`d-flex align-items-center justify-content-center ${styleTemplate4.backGroundSlider}`}
          >
            <img
              src={image}
              className="d-block w-100 opacity-50"
              alt="..."
              style={{ objectFit: "cover", height: sizeCarrusel }}
            />
            <div
              className={`${styleTemplate4.containerTextSlider} ${styleTemplate4.containerTextSliderPosition}`}
            >
              <h2 className="animate__animated animate__fadeInDown fs-1">
                {slideText[index].title}
              </h2>
              <p className="animate__animated animate__fadeInUp animate__slow animate__delay-1s fs-4 text-warning">
                {slideText[index].subtitle}
              </p>
            </div>
          </div>
        </div>
      );
      return;
    }

    buttons.push(
      <button
        type="button"
        className={styleTemplate4.redondeado}
        data-bs-target={`#${id}`}
        data-bs-slide-to={index}
        aria-label={`Slide ${index + 1}`}
      />
    );
    carrouselItems.push(
      <div className="carousel-item">
        <div
          className={`d-flex align-items-center justify-content-center ${styleTemplate4.backGroundSlider}`}
        >
          <img
            src={image}
            className="d-block w-100 opacity-50"
            alt="..."
            style={{ objectFit: "cover", height: sizeCarrusel }}
          />
          <div
            className={`${styleTemplate4.containerTextSlider} ${styleTemplate4.containerTextSliderPosition}`}
          >
            <h2 className="animate__animated animate__fadeInDown">
              {slideText[index].title}
            </h2>
            <p
              className={`animate__animated animate__fadeInUp animate__slow animate__delay-1s`}
            >
              {slideText[index].subtitle}
            </p>
          </div>
        </div>
      </div>
    );
  });

  let slider = (
    <>
      <div
        id={id}
        className="carousel slide carousel-fade"
        data-bs-ride="true"
        data-bs-interval="5000"
      >
        {indicatorsActive && (
          <div className="carousel-indicators">{buttons}</div>
        )}
        <div
          className={`carousel-inner  ${styleTemplate4.containerGeneralSlider}`}
        >
          {carrouselItems}
        </div>
        {controlsActive && (
          <>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide="prev"
            >
              <span
                className={`${styleTemplate4.carouselControlIcon}`}
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide="next"
            >
              <span
                className={`${styleTemplate4.carouselControlIcon}`}
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </>
        )}
      </div>
    </>
  );
  return slider;
};

export default useSliderBootstrap;
