import img1Album from '../../../../assets/img/templates/template6/img/index/album/beach-g1a67a549f_1920.jpg';
import img2Album from '../../../../assets/img/templates/template6/img/index/album/infant-gd98ee886f_1920.jpg';
import img3Album from '../../../../assets/img/templates/template6/img/index/album/bride-gb7f09d39c_1920.jpg';
import img4Album from '../../../../assets/img/templates/template6/img/index/album/wedding-g7f79e5756_1920.jpg';
import img5Album from '../../../../assets/img/templates/template6/img/index/album/family-ge0d4ef818_1920.jpg';
import img6Album from '../../../../assets/img/templates/template6/img/index/album/couple-gdd3c110d1_1920.jpg';
import img7Album from '../../../../assets/img/templates/template6/img/index/album/wedding-g26fa3c555_1920.jpg';
import img8Album from '../../../../assets/img/templates/template6/img/index/album/young-woman-g8e56e5150_1920.jpg';
import img9Album from '../../../../assets/img/templates/template6/img/index/album/beach-g1a67a549f_1920.jpg';

export const albumTemplate6 = [
  { img: img1Album, filter: 'filter-nosotros', key: 0 },
  { img: img2Album, filter: 'filter-familia', key: 1 },
  { img: img3Album, filter: 'filter-nosotros', key: 2 },
  { img: img4Album, filter: 'filter-viajes', key: 3 },
  { img: img5Album, filter: 'filter-familia', key: 4 },
  { img: img6Album, filter: 'filter-nosotros', key: 5 },
  { img: img7Album, filter: 'filter-viajes', key: 6 },
  { img: img8Album, filter: 'filter-viajes', key: 7 },
  { img: img9Album, filter: 'filter-familia', key: 8 },
];
