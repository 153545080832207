import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../App/Home/Home-Layout';
import Nosotros from '../App/Nosotros/Nosotros-Layout';
import { AvisoPrivacidad } from '../App/Aviso-Privacidad/AvisoPrivacidad';
import { Contacto } from '../App/Contacto/Contacto';
import { Invitaciones } from '../App/Invitaciones/Invitaciones';
import { PreguntasFrecuentes } from '../App/Preguntas-frecuentes/PreguntasFrecuentes';

import { Templates } from '../App/Templates/Templates';
import HomeLayout from './LayoutRoutes/HomeLayout';
import TemplatesLayout from './LayoutRoutes/TemplatesLayout';
import { Blog } from '../App/blog/Blog';
import { PlanificaBoda } from '../App/blog/views/PlanificaBoda';
import { LunaMiel } from '../App/blog/views/LunaMiel';

// import { Templates } from '../App/templates/Templates';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={'/home'} />} />
        <Route path="/" element={<HomeLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/invitaciones" element={<Invitaciones />} />
          <Route
            path="/preguntas-frecuentes"
            element={<PreguntasFrecuentes />}
          />
          <Route path="/aviso-Privacidad" element={<AvisoPrivacidad />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/planifica-tu-boda" element={<PlanificaBoda />} />
          <Route path="/luna-de-miel" element={<LunaMiel />} />
          <Route path="/contacto" element={<Contacto />} />
        </Route>
        <Route path="/template" element={<TemplatesLayout />}>
          <Route path="/template/:id" element={<Templates />} />
        </Route>
        <Route path="*" element={<Navigate to={'/home'} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
