import rose from "../../../assets/img/rose_draw_index.png";
import slider1 from "../../../assets/img/damas.jpg";
import slider2 from "../../../assets/img/pexels-tembela-bohle-1114425.jpg";
import slider3 from "../../../assets/img/pexels-marina-utrabo-1729797.jpg";
import "./Slider.css";
const Slider = ({size}) => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner backgroud-slider">
        <div className="carousel-item active" data-bs-interval="3000">
          <div className="row justify-content-between align-items-center">
            <div className="col-6 justify-content-center">
              <img
                src={rose}
                className="w-75 align-self-center opacity-25"
                alt="..."
              />
            </div>
            <div className="col-6">
              <img src={slider1} className="d-block w-100" alt="..." style={{ objectFit: "cover", height: size }}/>
            </div>
            <div className="carousel-caption">
              <div className="row">
                <div className="col-6">
                  <h1 className="slider-title">Invitaciones Digitales</h1>
                  <h2 style={{fontSize: '15px', color: 'white'}}>Servicios de desarrollo profesional</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="3000">
          <div className="row justify-content-end align-items-center">
            <div className="col-6">
              <img src={slider2} className="d-block w-100" alt="..." style={{ objectFit: "cover", height: size }}/>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <img
                src={rose}
                className="w-75 align-self-center opacity-25"
                alt="..."
              />
            </div>
            <div className="carousel-caption right">
              <div className="row">
                <div className="col-6">
                  <p className="slider-title">Invitaciones Digitales</p>
                  <h2 style={{fontSize: '15px', color: 'white'}}>Servicios de desarrollo profesional</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="3000">
          <div className="row justify-content-between align-items-center">
            <div className="col-6 justify-content-center">
              <img
                src={rose}
                className="w-75 align-self-center opacity-25"
                alt="..."
              />
            </div>
            <div className="col-6">
              <img src={slider3} className="d-block w-100" alt="..." style={{ objectFit: "cover", height: size }}/>
            </div>
            <div className="carousel-caption">
              <div className="row">
                <div className="col-6">
                  <p className="slider-title">Invitaciones Digitales</p>
                  <h2 style={{fontSize: '15px', color: 'white'}}>Servicios de desarrollo profesional</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Slider;
