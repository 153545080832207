import imgTemplate2H from '../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg';
import imgTemplate2M from '../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg';

export const nombramientosTemplate2 = [
  {
    nombre: 'Luis',
    cargo: 'Padrino',
    img: imgTemplate2H,
    key: '0',
  },
  {
    nombre: 'Victoria',
    cargo: 'Madrina',
    img: imgTemplate2M,
    key: '1',
  },
  {
    nombre: 'Arturo',
    cargo: 'Padrino',
    img: imgTemplate2H,
    key: '2',
  },
  {
    nombre: 'Jennifer',
    cargo: 'Madrina',
    img: imgTemplate2M,
    key: '3',
  },
];
