
import { nombramientosTemplate2 } from '../js/nombramiento'

export const NombramientoTemplete2 = ({styleTemplate2}) => {
  return (
   <section id="team" className={`${styleTemplate2.team} ${styleTemplate2['section-bg']}`}>
      <div className={`container ${styleTemplate2.container2}`}data-aos="fade-up">

        <div className={styleTemplate2['section-title']}>
          <h2>Nombramientos</h2>
          <p>No sabes cuanto había esperado tu llegada y los momentos en que soñaba, con el instante en el que por fin nos conoceríamos. Hoy más que nunca, sé que eres lo que más he deseado.</p>
        </div>

        <div className="row nombramientos-div">
            {nombramientosTemplate2.map((nombramiento)=>(
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={nombramiento.key}>
        <div className={styleTemplate2.member} data-aos="fade-up" data-aos-delay="100">
          <div className={styleTemplate2['member-img']}>
            <img src={nombramiento.img} className="img-fluid" alt=""/>
          </div>
          <div className={styleTemplate2['member-info']}>
            <h4>{nombramiento.nombre}</h4>
            <span>{nombramiento.cargo}</span>
          </div>
        </div>
      </div>
            ))}
        </div>

      </div>
    </section>
  )
}
