export const HeroTemplate2 = ({styleTemplate2}) => {
  return (
    <section id="hero" className={`d-flex align-items-center ${styleTemplate2.hero}`}>

    <div className={`container ${styleTemplate2.container2}`} data-aos="zoom-out" data-aos-delay="100">
      <div className="row">
        <div className="col-xl-6">
          <h1>Bautizo de Christopher</h1>
          <h2>Gracias por acompañarnos</h2>
          <a href="#about" style={{textDecoration:'none'}} className={`${styleTemplate2['btn-get-started']} scrollto`}>Empecemos</a>
        </div>
      </div>
    </div>
  </section>
  )
}
