

export const MensajeTemplte3 = ({styleTemplate3}) => {
  return (
    <>
     <section id="cta" className={styleTemplate3.cta}>
      <div className={`container  ${styleTemplate3.container2}`}>

        <div className="row" data-aos="zoom-in">
          <div className="col-lg-9 text-center text-lg-start">
            <h3 style={{fontSize: '40px'}}>Que florezca para ti la felicidad</h3>
            <p style={{fontSize: '30px', fontWeight: 'bolder'}}>En tus quince primaveras deseo que florezca para ti la felicidad, y que Dios bendiga cada día de tu vida. ¡Felicidades, princesa!</p>
          </div>
        </div>

      </div>
    </section>
    
    </>
  )
}
