

export const TitleInvitaciones = () => {
  return (
    <>
    <div style={{height:100}}></div>
    <div className="row">
            <div className="col">
                <p className="text-1-espe">Invitaciones disponibles:</p>
                <p className="text-2-espe">Completamente personalizadas y responsivas.</p>
            </div>
        </div>
    </>
  )
}
