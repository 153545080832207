
import imgWhats from '../../../assets/img/boton-whatsapp.png';
import imgFb from '../../../assets/img/boton_fb.jpg';
import imgInsta from '../../../assets/img/boton_insta.png';
import imgTik from '../../../assets/img/boton_tik.png';
import './css/whatsApp.css';
export const WhatsApp = () => {
  return (
    <>
    <div className="btn-whatsapp">
      <a href="https://api.whatsapp.com/send?phone=5638481998" rel="noopener noreferrer" target="_blank">
        <img className="btn-whats" src={imgWhats} alt="contacto whatsapp" title='contacto whatsapp'/>
      </a>
      {/* <a href="https://www.instagram.com/click_invitaciones_/" rel="noopener noreferrer" target="_blank">
        <img className="btn-whats" src={imgTik} alt="contacto facebook" title='contacto facebook'/>
      </a> */}
      <a href="https://www.instagram.com/click_invitaciones_/" rel="noopener noreferrer" target="_blank">
        <img className="btn-whats" src={imgInsta} alt="contacto facebook" title='contacto facebook'/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100088968402514" rel="noopener noreferrer" target="_blank">
        <img className="btn-whats" src={imgFb} alt="contacto facebook" title='contacto facebook'/>
      </a>
    </div>
    </>
  )
}
