import { Link } from "react-router-dom";
export const ServiciosTemplate4 = ({ styleTemplate4 }) => {
  return (
    <div id="services" className="services">
      <div className="container">
        <h2 className={`${styleTemplate4.serviciosTitle}`}>Información del Evento</h2>
        <div className="row">
          <div
            className="col-md-6"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <div className={`${styleTemplate4.iconBox}`} 
            style={{height:"94%"}} >
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="bi">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-friends"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#2c3e50"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="7" cy="5" r="2" />
                      <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                      <circle cx="17" cy="5" r="2" />
                      <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
                    </svg>
                  </i>
                </div>
                <div className="col">
                  <h4>
                    <a
                      className={`${styleTemplate4.titleCards}`}
                      href="#sliderTemplate4"
                    >
                      Código de Vestimenta
                    </a>
                  </h4>
                  <p>
                    Formal / Smart Casual</p>
                  <p>(No podrás entrar sin el código de vestimenta)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 mt-4 mt-md-0"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <div className={`${styleTemplate4.iconBox}`}>
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                <i className="bi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-building-castle"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 19v-2a3 3 0 0 0 -6 0v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14h4v3h3v-3h4v3h3v-3h4v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                  <line x1="3" y1="11" x2="21" y2="11" />
                </svg>
              </i>
                </div>
                <div className="col">
                  <h4>
                    <a
                      className={`${styleTemplate4.titleCards}`}
                      href="#sliderTemplate4"
                    >
                      Hospedaje
                    </a>
                  </h4>
                  <p>
                    Te recomendamos los hoteles Holiday Inn que se encuentran cerca del evento de los cuáles habrá transporte directo por parte de la organización que te llevara y traerá hacia la convención.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 mt-4 mt-md-0"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <div className={`${styleTemplate4.iconBox}`} style={{height:"91%"}}>
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                <i className="bi">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mood-kid"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="9" y1="10" x2="9.01" y2="10" />
                  <line x1="15" y1="10" x2="15.01" y2="10" />
                  <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                  <path d="M12 3a2 2 0 0 0 0 4" />
                </svg>
              </i>
                </div>
                <div className="col">
                  <h4>
                    <a
                      className={`${styleTemplate4.titleCards}`}
                      href="#sliderTemplate4"
                    >
                      Niños
                    </a>
                  </h4>
                  <p>
                  No se admitirá la presencia de niños en el evento.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 mt-4 mt-md-0"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <div className={`${styleTemplate4.iconBox}`}>
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                <i className="bi ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-tools-kitchen-2"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />
                </svg>
              </i>
                </div>
                <div className="col">
                  <h4>
                    <a
                      className={`${styleTemplate4.titleCards}`}
                      href="#sliderTemplate4"
                    >
                      Menú
                    </a>
                  </h4>
                  <p>
                  Contaremos con comida carnivora, vegetariana y algunas opciones veganas en diferentes stands del evento.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
