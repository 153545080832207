import album1 from '../../../../assets/img/templates/template1/img/index/album/beach-g1a67a549f_1920.jpg';
import album2 from '../../../../assets/img/templates/template1/img/index/album/infant-gd98ee886f_1920.jpg';
import album3 from '../../../../assets/img/templates/template1/img/index/album/bride-gb7f09d39c_1920.jpg';
import album4 from '../../../../assets/img/templates/template1/img/index/album/wedding-g7f79e5756_1920.jpg';
import album5 from '../../../../assets/img/templates/template1/img/index/album/family-ge0d4ef818_1920.jpg';
import album6 from '../../../../assets/img/templates/template1/img/index/album/couple-gdd3c110d1_1920.jpg';
import album7 from '../../../../assets/img/templates/template1/img/index/album/wedding-g26fa3c555_1920.jpg';
import album8 from '../../../../assets/img/templates/template1/img/index/album/young-woman-g8e56e5150_1920.jpg';
import album9 from '../../../../assets/img/templates/template1/img/index/album/beach-g1a67a549f_1920.jpg';

export const albumTemplate1 = [
  { key: 1, img: album1, alt: '', filter: 'filter-nosotros' },
  { key: 2, img: album2, alt: '', filter: 'filter-familia' },
  { key: 3, img: album3, alt: '', filter: 'filter-nosotros' },
  { key: 4, img: album4, alt: '', filter: 'filter-viajes' },
  { key: 5, img: album5, alt: '', filter: 'filter-familia' },
  { key: 6, img: album6, alt: '', filter: 'filter-nosotros' },
  { key: 7, img: album7, alt: '', filter: 'filter-viajes' },
  { key: 8, img: album8, alt: '', filter: 'filter-viajes' },
  { key: 9, img: album9, alt: '', filter: 'filter-familia' },
];
