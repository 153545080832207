import { useEffect } from "react";
import musica from "../../../../assets/music/templates/cancion.mp3";
import AOS from "aos";

export const MusicTemplate4 = () => {
  return (
    <>
      <div className="container" data-aos="fade-up" data-aos-duration="500">
        <div className="row">
          <div className="col text-center">
            <audio id="my-audio" autoPlay controls loop="loop">
              <source src={musica} />
              Navegador no compatible
            </audio>
          </div>
        </div>
      </div>
    </>
  );
};
