import logo from "../../../assets/img/Clik.png";
import { Link, NavLink } from "react-router-dom";
const HeaderC = () => {

  const headerHandler = () => {
    let menu=document.getElementById('navbarNavAltMarkup');
    menu.className = "navbar-collapse collapse"
  };

 let activeStyle = {
    textDecoration: "underline",
    fontWeight: 'bold',
  };
  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg bg-light shadow">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/home">
            <img
              src={logo}
              alt=""
              width="200px"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse`} id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <NavLink style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } className="nav-link" to="home" onClick={headerHandler}>
                Inicio
              </NavLink>
              <NavLink style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } className="nav-link" to="nosotros" onClick={headerHandler}>
                Nosotros
              </NavLink>
              <NavLink style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } className="nav-link" to="invitaciones" onClick={headerHandler}>
                Invitaciones
              </NavLink>
              <NavLink style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } className="nav-link" to="blog" onClick={headerHandler}>
                Blog
              </NavLink>
              <NavLink style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } className="nav-link" to="contacto" onClick={headerHandler}>
                Contacto
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderC;
