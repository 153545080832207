import imagen1 from "../../../assets/img/portadas/template1.jpg";
import imagen2 from "../../../assets/img/portadas/template2.jpg";
import imagen3 from "../../../assets/img/portadas/template3.jpg";
import imagen4 from "../../../assets/img/portadas/template4.jpg";
import imagen5 from "../../../assets/img/portadas/template5.jpg";
import imagen6 from "../../../assets/img/portadas/template6.jpg";

export const templatesData = [
  {
    nombre: "Bodas",
    descripcion:
      "Impresiona a todos tus invitados con las increíbles invitaciones en el mejor día de tu vida, compartiendo fantásticos momentos con tus seres queridos, nosotros queremos ser parte de ese momento.",
    img: imagen1,
    url: "invitacion1",
    key: "0",
  },
  {
    nombre: "Bautizos",
    descripcion:
      "Comparte con alegría este gran momento con toda tu familia y amigos invitándolos con nuestras increíbles invitaciones llenas de creatividad y cariño,  queremos ser parte de ese momento.",
    img: imagen2,
    url: "invitacion2",
    key: "1",
  },
  {
    nombre: "XV años",
    descripcion:
      "Solo se cumplen una vez en la vida y tanto tus familiares como tus amigos deben estar presentes cumpliendo este gran sueño, nosotros queremos ser parte de ese momento.",
    img: imagen3,
    url: "invitacion3",
    key: "2",
  },
  {
    nombre: "Eventos",
    descripcion:
      "Reúne a tus personas favoritas  en los increíbles eventos con ayuda de nuestras fantásticas invitaciones, nosotros queremos ser parte de ese momento",
    img: imagen4,
    url: "invitacion4",
    key: "3",
  },
  {
    nombre: "Baby shower",
    descripcion:
      "Invita a todos tus amigos y familiares a compartir una nueva etapa de tu vida, siendo parte de un momento increíble para ti, nosotros queremos ser parte de ese momento.",
    img: imagen5,
    url: "invitacion5",
    key: "4",
  },
  {
    nombre: "Boda",
    descripcion:
      "Impresiona a todos tus invitados con las increíbles invitaciones en el mejor día de tu vida compartiendo maravillosos momentos con tus seres queridos, nosotros queremos ser parte de ese momento.",
    img: imagen6,
    url: "invitacion6",
    key: "5",
  },
];
