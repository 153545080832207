import { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";


export const useIsotopeReact = () => {
  const [activeFilter, setActiveFilter] = useState(0);

  const isotope = useRef();

  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    isotope.current = new Isotope(".portfolio-container", {
      itemSelector: ".portfolio-item",
    });

    return () => isotope.current.destroy();
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key, ActiveFilter) => () => {
    setActiveFilter(ActiveFilter)
     return setFilterKey(key)
    
};


const [scrollY, setScrollY] = useState(false);
    const navRef = useRef();
            
    navRef.current = scrollY;
    useEffect(() => {

        const handleScroll = () => {
            const show = window.scrollY > 20;
          if (navRef.current !== show) {
            setScrollY(show)
          }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
          document.removeEventListener('scroll', handleScroll)
        }
      }, [])


      if (scrollY) {
        isotope.current.arrange({filter: `*`});
      }

  return {
    handleFilterKeyChange,
    activeFilter,
  };
};
