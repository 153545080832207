import { Link } from "react-router-dom";

const CarruselItem = ({ info }) => {
  return (
    <div className="col p-3">
       <Link style={{textDecoration: 'none'}} to="/Invitaciones">
      <div className="card shadow" style={{ height: "100%" }}>
        <div className="card-body justify-content-center">
          <img className="col-12 img-fluid mb-3" src={info.imagen} alt={info.titulo} />
          <p style={{color:'black'}} className="card-title">{info.titulo}</p>
          <p style={{color:'black'}} className="description-card">{info.descripcion}</p>
            <p style={{color:'black', fontWeight: 'bold',}}>Ver más</p>
        </div>
      </div>
      </Link>
    </div>
  );
};

export default CarruselItem;
