import { palabrasTemplate6 } from "../js/palabras-template6"


export const NombramientoTemplate6 = ({styleTemplate6}) => {
  return (
    <section id="team" className={styleTemplate6.team}>
      <div className="container">

        <div className={styleTemplate6['section-title']} data-aos="zoom-out">
          <h2>Click Invitaciones</h2>
          <p>Nombramientos</p>
        </div>

        <div className="row nombramientos-div">
          {palabrasTemplate6.map(nombramiento=>(
            <div key={nombramiento.key} className="col-lg-3 col-md-6 d-flex align-items-stretch">
        <div className={styleTemplate6.member} data-aos="fade-up">
          <div className={styleTemplate6['member-img']}>
            <img src={nombramiento.img} className="img-fluid" alt=""/>
          </div>
          <div className={styleTemplate6['member-info']}>
            <h4>{nombramiento.name}</h4>
            <span>{nombramiento.position}</span>
          </div>
        </div>
      </div>
          ))}

        </div>

      </div>
    </section>
  )
}
