import { Helmet } from 'react-helmet'
import Spacer from '../../Comun/Componentes/Spacer'
import img1 from '../../../assets/img/blog/luna/pexels-asad-photo-maldives-1024993.jpg'
import img2 from '../../../assets/img/blog/luna/pexels-andrea-piacquadio-3756158.jpg'
import img3 from '../../../assets/img/blog/luna/pexels-vlada-karpovich-4050300.jpg'
import img4 from '../../../assets/img/blog/luna/pexels-aysegul-alp-14452884.jpg'
import img5 from '../../../assets/img/blog/luna/pexels-picjumbocom-210661.jpg'
import img6 from '../../../assets/img/blog/luna/pexels-susanne-jutzeler-sujufoto-5140458.jpg'
import { AdsComponent } from '../../adsense/AdsComponent '
export const LunaMiel = () => {
return (
<>

    <Helmet>
        <title>Boda de ensueño</title>
        <meta
            content="Consejos para hacer de tu luna de miel una experiencia inolvidable: destinos, actividades, itinerarios y más."
            name="description">
        </meta>
        <meta
            content="luna de miel, experiencia inolvidable, consejos, destino,reservaciones, promociones para parejas, itinerario, actividades, clima,lista de deseos, relajación, disfrutar del momento presente"
            name="keywords">
        </meta>
    </Helmet>
    <Spacer space={100} />
    <div className="container">
        <div className="row">
            <div className="col">
                <h1 className="text-center">Consejos para hacer de tu luna de miel una experiencia inolvidable.</h1>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <div className="card p-3 shadow text-center">
                     <p>La luna de miel es un <strong>momento muy especial para las parejas recién casadas</strong>. Es una oportunidad para
                    relajarse,
                    disfrutar de la compañía del otro y celebrar su amor. Sin embargo, planear una luna de miel puede
                    ser
                    estresante. Aquí te dejamos algunos consejos para que puedas hacer de <strong>tu luna de miel una
                    experiencia
                    inolvidable</strong>.</p>
                </div>
            </div>
        </div>
        <div className="row mb-5">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <h3 className="text-center">Elije el destino adecuado</h3>
                <p>Elige un destino que sea <strong>significativo para ti y tu pareja</strong> o que ambos hayan querido visitar. Si no
                    puedes
                    decidir, piensa en las actividades que les gustaría hacer y busca destinos que ofrezcan eso.</p>
                    <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img1} alt="planificador de bodas" title="planificador de bodas"/>

            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <h3 className="text-center">Haz reservaciones con anticipación</h3>
                <p><strong>Asegúrate de hacer reservaciones con anticipación</strong> para tu alojamiento, vuelos y actividades. De esta
                    manera,
                    podrás obtener las mejores tarifas y asegurarte de tener una habitación disponible. Además, puedes
                    buscar
                    promociones especiales para parejas recién casadas.</p>
                    <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img2} alt="planificador de bodas" title="planificador de bodas"/>
            </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
        <div className="row mb-5">
            <div className="col">
                <h3 className='text-center'>Crea un itinerario</h3>
                <p> No querrás pasar todo tu tiempo en la habitación de hotel. Asegúrate de crear un itinerario que
                    incluya
                    actividades divertidas, románticas y relajantes. No te preocupes si no cumples todo en la lista, <strong>¡la
                    idea es
                    disfrutar del tiempo juntos!</strong></p>
                    <div className='text-center'>
                        <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img3} alt="planificador de bodas" title="planificador de bodas"/>
                    </div>
            </div>
        </div>
        <div className="row mb-5">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <h3 className="text-center">Considera la temporada</h3>
                <p>Si bien es cierto que cada destino tiene su encanto en cada temporada, debes <strong>considerar la temporada
                    en la que
                    viajarás</strong> para poder disfrutar al máximo de tu luna de miel. Si tu destino es muy frío o muy
                    caluroso, asegúrate
                    de estar preparado para las condiciones climáticas.</p>
                    <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img4} alt="planificador de bodas" title="planificador de bodas"/>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <h3 className="text-center">Haz una lista de deseos</h3>
                <p><strong>Haz una lista de deseos para tu luna de miel</strong>. Incluye cosas como: "Ver la puesta de sol en la playa",
                    "Probar la
                    comida local" o "Hacer una excursión en bote". Trata de cumplir algunos de estos deseos durante tu
                    viaje.</p>
                    <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img5} alt="planificador de bodas" title="planificador de bodas"/>
            </div>
        </div>
        <div className="row mb-5">
            <div className="col">
                <h3 className='text-center'>¡¡No te estreses!!</h3>
                <p>Recuerda que esta es una <strong>oportunidad para relajarte y disfrutar de la compañía del otro</strong>. No te
                    estreses
                    demasiado si algo no sale según lo planeado. Toma las cosas con calma y diviértete.</p>
                    <div className='text-center'>
<img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img6} alt="planificador de bodas" title="planificador de bodas"/>
                    </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <p>
                    En resumen, hacer de <strong>tu luna de miel una experiencia inolvidable</strong> requiere un poco de planificación,
                    pero también
                    de relajación y disfrute. Elige el destino adecuado, haz reservaciones con anticipación, crea un
                    itinerario,
                    considera la temporada, haz una lista de deseos y no te estreses demasiado. <strong>¡Feliz luna de miel!</strong></p>
            </div>
        </div>
        <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
      </div>
    </div>
</>
)
}