import { useEffect } from "react";
import Aos from "aos";
import {
  AlbumTemplate4,
  ContactoUbicacionTemplate4,
  DateTemplate4,
  FooterTemplate4,
  HeaderTemplate4,
  CarruselTemplate4,
  MusicTemplate4,
  NombramientosTemplate4,
  NosotrosTemplate4,
  ServiciosTemplate4,
} from "./components";
import styleTemplate4 from "./template4.module.css";
import dayjs from "dayjs";
import Spacer from "../../Comun/Componentes/Spacer";
import { useState } from "react";
import useWindowsSize from "../../Comun/Hooks/useWindowsSize";
import "./components/generalStyleTemplate4.css"
import { FireworksComp } from "../../Comun/Componentes/FireworksComp";

export const Invitacion4 = () => {
  let dateEvent = dayjs("2023-11-22");

  useEffect(() => {
    Aos.init();
  });

  const { innerHeight } = useWindowsSize();
  const [cardHeight, setCardHeight] = useState();

  let size = Math.floor(innerHeight) - cardHeight;

  let sizeUp = cardHeight;

  return (
    <>
      <HeaderTemplate4
        styleTemplate4={styleTemplate4}
        setCardHeight={setCardHeight}
      />
      <Spacer space={sizeUp} />
      <CarruselTemplate4 styleTemplate4={styleTemplate4} sizeCarrusel={size} />
      <FireworksComp/>
      <Spacer space={50}/>
      <MusicTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <DateTemplate4 styleTemplate4={styleTemplate4} dateEvent={dateEvent} />
      <Spacer space={50}/>
      <NosotrosTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <ServiciosTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <NombramientosTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <AlbumTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <ContactoUbicacionTemplate4 styleTemplate4={styleTemplate4} />
      <Spacer space={50}/>
      <FooterTemplate4 styleTemplate4={styleTemplate4} />
    </>
  );
};
