import { useEffect, useState } from "react";
import { countDownJs } from "../js/CountDown";


export const useCountDown = (dateEvent) => {
  const initial = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days:'00'
    }
    const [countDownState, setCountDownState] = useState(initial);
    useEffect(() => {
     const intervalId =  setInterval(() => {
        UpdateTime(dateEvent);
     }, 1000);
     return () => clearInterval(intervalId)
    }, [dateEvent]);

    function UpdateTime(countdown) {
       setCountDownState(countDownJs(countdown))
    }
  return {
    ...countDownState
  }
}
