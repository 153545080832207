import { useCountDown } from "../../../Comun/Hooks/useCountDown";

export const DateTemplate1 = ({ styleTemplate1, dateEvent }) => {
  const { days, hours, minutes, seconds } = useCountDown(dateEvent);

  let daysS = days.split(" ");
  let hoursS = hours.split(" ");
  let minutesS = minutes.split(" ");
  let secondsS = seconds.split(" ");

  return (
    <>
      <div style={{ height: 50 }}></div>
      <div className="container" data-aos="fade-up">
        <div className={styleTemplate1["section-title"]}>
          <h2>Click Invitaciones</h2>
          <p>El evento más esperado del año</p>
          <p id="countdown"></p>
        </div>
        <div className="row">
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div>
              <p
                className="text-center fw-bolder fs-2"
                style={{ color: " #151515" }}
                id="dias"
              >
                {daysS[0]}
                <br />
                {daysS[1]}
              </p>
            </div>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="horas"
            >
              {hoursS[0]}
              <br />
              {hoursS[1]}
            </p>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="minutos"
            >
              {minutesS[0]}
              <br />
              {minutesS[1]}
            </p>
          </div>
          <div
            className="col pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <p
              className="text-center fw-bolder fs-2"
              style={{ color: " #151515" }}
              id="segundos"
            >
              {secondsS[0]}
              <br />
              {secondsS[1]}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
