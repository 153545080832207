
import { Link } from 'react-router-dom';

export const FooterTemplate2 = ({styleTemplate2}) => {
  
  return (
     <footer id={styleTemplate2.footer}>

    <div className="container d-md-flex py-4">

      <div className="me-md-auto text-center text-md-start">
        <div className={styleTemplate2.copyright}>
          &copy; Copyright <strong><span><Link style={{textDecoration:'none', color: 'white'}} to={'/'}>Click Invitaciones</Link></span></strong>. Todos los derechos reservados.<strong><span> <Link style={{textDecoration:'none', color: 'white'}} to={'/aviso-privacidad'}>Aviso de Privacidad</Link></span></strong>
        </div>
      </div>
      <div className="social-links text-center text-md-end pt-3 pt-md-0">
        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
      </div>
    </div>
  </footer>
  )
}
