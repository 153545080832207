import { servicios } from "../js/servicios-data"

export const ServiciosTemplate2 = ({styleTemplate2}) => {
  return (
   <section id="services" className={`${styleTemplate2.services} ${styleTemplate2['section-bg']}`}>
  <div className={`container ${styleTemplate2.container2}`} data-aos="fade-up">

    <div className={styleTemplate2['section-title']}>
      <h2>Detalles</h2>
      <p style={{fontSize: '30px'}}>Los bebés nos traen una pieza del cielo a la tierra.</p>
    </div>

    <div className="row justify-content-center">

{servicios.map(servicio =>(
<div key={servicio.key} className="col-md-6">
        <div className={styleTemplate2['icon-box']} data-aos="fade-up" data-aos-delay="100">
          <i className="bi">{servicio.icon}</i>
          <h4><a style={{textDecoration:'none'}} href="#">{servicio.title}</a></h4>
          <p>{servicio.description}</p>
          <p style={{visibility:'hidden'}}>Voluptatum deleniti atque corrupti quos dolores et quas molestias</p>
        </div>
      </div>
))}
    </div>

  </div>
</section>
  )
}
