import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage } from 'firebase/messaging';

import {
  collection,
  getFirestore,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: 'AIzaSyCI0rqMKntsrxrUpRnC-CvVHdCIXAH4rhw',
  authDomain: 'invitaciones-b3880.firebaseapp.com',
  projectId: 'invitaciones-b3880',
  storageBucket: 'invitaciones-b3880.appspot.com',
  messagingSenderId: '189105116589',
  appId: '1:189105116589:web:9e7c2cf9d09f27861e7d24',
  measurementId: 'G-9WB0YQ0HZ3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export const saveForme = (nombre, whatsApp, correo, asunto) => {
  addDoc(collection(db, 'invitaciones'), {
    nombre,
    whatsApp,
    correo,
    asunto,
    hora: serverTimestamp(),
  });
};

const messaging = getMessaging(app);
