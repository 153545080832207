import musica from '../../../../assets/music/templates/cancion.mp3'

export const MusicTemplate6 = () => {
  return (
    <>
    <div style={{height:'50px'}}></div>
<div className="container" data-aos="fade-up">

    <div className="row">
        <div className="col" style={{textAlign:'center'}}>
            <audio id="my-audio-6" autoPlay controls loop="loop">
                <source src={musica}/>
                Navegador no compatible
            </audio>

        </div>
    </div>

</div>
<div style={{height:'50px'}}></div>
    
    </>
  )
}
