import { useIsotopeReact } from '../../../Comun/Hooks/useIsotopeReact';
import { albumTemplte3 } from '../js/album-template3';



export const AlbumTemplate3 = ({styleTemplate3}) => {
 const {handleFilterKeyChange, activeFilter} = useIsotopeReact();
  
  return (
     <section id="portfolio" className={styleTemplate3.portfoio}>
      <div className={`container ${styleTemplate3.container2}`} data-aos="fade-up">

        <div className={styleTemplate3['section-title']}>
          <h2>Album</h2>
          <p>Sofía Valentina</p>
          <p>Mis XV</p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id={styleTemplate3['portfolio-flters']}>
              <li onClick={handleFilterKeyChange('*', 0)} className={`${activeFilter===0 ? styleTemplate3['filter-active'] : "" } ${styleTemplate3.albumFilterItem}`} >Todas</li>
              <li onClick={handleFilterKeyChange('filter-lago', 1)} className={`${activeFilter===1 ? styleTemplate3['filter-active'] : "" } ${styleTemplate3.albumFilterItem}`} >Lago</li>
              <li onClick={handleFilterKeyChange('filter-vestido', 2)} className={`${activeFilter===2 ? styleTemplate3['filter-active'] : "" } ${styleTemplate3.albumFilterItem}`} >Vestido</li>
              <li onClick={handleFilterKeyChange('filter-puente', 3)} className={`${activeFilter===3 ? styleTemplate3['filter-active'] : "" } ${styleTemplate3.albumFilterItem}`} >Puente</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container">

{albumTemplte3.map(album => (
          <div className={`col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item ${album.filter} align-items-center`}>
            <a href={album.img}>
            <img src={album.img} className="img-fluid" alt=""/>
            </a>
          </div>

))}

        </div>

      </div>
    </section>
  )
}
