

export const MensajeTemplate1 = ({styleTemplate1}) => {
  return (
    <section id="cta" className={styleTemplate1.cta}>
      <div className="container" data-aos="zoom-in">

        <div className="text-center">
          <h3 style={{color:'black'}}> “A veces siento que estoy soñando cuando me doy cuenta que siempre estarás a mi lado”.</h3>
          <p style={{color:'black'}}> “Una de las cosas más importantes para mí es que estén bien y que seas muy feliz. Déjame cuidarte y hacerte feliz, amor cada día junto a ti es una oportunidad más de demostrarte cuánto me importas”</p>
        </div>

      </div>
    </section>
  )
}
