
import { useParams } from 'react-router-dom';
import { Invitacion1 } from './template1/Invitacion1';
import { Invitacion2 } from './template2/Invitacion2';
import { Invitacion3 } from './template3/Invitacion3';
import { Invitacion4 } from "./template4/Invitacion4";
import { Invitacion5 } from './template5/Invitacion5';
import { Invitacion6 } from './template6/Invitacion6';

export const Templates = () => {
  const { id } = useParams();
  let intvitacion;
  switch (id) {
    case "invitacion1":
      intvitacion = <Invitacion1 />;
      break;
    case "invitacion2":
      intvitacion = <Invitacion2 />;
      break;
    case "invitacion3":
      intvitacion = <Invitacion3 />;
      break;
    case "invitacion4":
      intvitacion = <Invitacion4 />;
      break;
    case "invitacion5":
      intvitacion = <Invitacion5 />;
      break;
    case "invitacion6":
      intvitacion = <Invitacion6 />;
      break;
      default:
      break;
    
  }

  return (
    <>
      {intvitacion}
    </>
  );
};
