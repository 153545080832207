import Slider from "react-slick";

export const useSliderSlike = (toShow) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: toShow,
      slidesToScroll: 1,
      lazyLoad: true,
      autoplay: true,
      adaptiveHeight: true,
       autoplaySpeed: 3000,
       responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return {
    settings,
    Slider
  }
}
