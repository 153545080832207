import { useFormTemplates } from "../../../Comun/Hooks/useFormTemplates";


export const DireccionConfirmacionTemplte2 = ({styleTemplate2}) => {
  const {onInputChange, onSumit, nombre, confirmacion} = useFormTemplates({
    nombre:'', confirmacion: ''
  });
  return (
    <section id="contact" className={styleTemplate2.contact}>
  <div className="container" data-aos="fade-up">

    <div className={styleTemplate2['section-title']}>
      <h2>Dirección y Confirmación</h2>
      <p>Aquí encontraras información de la ubicación y un mapa para tener mejores referencias, no olvides confirmar tu asistencia es muy importante.</p>
    </div>
    <div>
      <iframe style={{border:'0', width:'100%', height:'270px'}}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.2398736272694!2d-99.12612908604444!3d19.48831303184559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f9a20cc7d7df%3A0xf7aa6c7c6d8240fa!2sParroquia%20del%20Se%C3%B1or%20de%20la%20Misericordia!5e0!3m2!1ses-419!2smx!4v1667879441638!5m2!1ses-419!2smx"
        frameBorder="0" allowFullScreen title='mapa'></iframe>
    </div>
    <div style={{height: 50}}></div>
    <div className="row" data-aos="fade-up" data-aos-delay="100">

      <div className="col-lg-6">

        <div className="row">
          <div className="col-md-12">
            <div className={styleTemplate2['info-box']}>
              <i className="bx"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-church"
                  width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
                  strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="3" y1="21" x2="21" y2="21" />
                  <path d="M10 21v-4a2 2 0 0 1 4 0v4" />
                  <line x1="10" y1="5" x2="14" y2="5" />
                  <line x1="12" y1="3" x2="12" y2="8" />
                  <path d="M6 21v-7m-2 2l8 -8l8 8m-2 -2v7" />
                </svg></i>
              <h3>Iglesia:</h3>
              <p>Av. Insurgentes Nte., Lindavista, Gustavo A. Madero, 07300 Ciudad de México, CDMX</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${styleTemplate2['info-box']} mt-4`}>
              <i className="bx"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2"
                  width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
                  strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg></i>
              <h3>Salón</h3>
              <p>Av. Insurgentes Nte., Lindavista, Gustavo A. Madero, 07300 Ciudad de México, CDMX</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${styleTemplate2['info-box']} mt-4`}>
              <i className="bx"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-2"
                  width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
                  strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="18" y2="6.01" />
                  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
                  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
                  <line x1="9" y1="4" x2="9" y2="17" />
                  <line x1="15" y1="15" x2="15" y2="20" />
                </svg></i>
              <h3>After</h3>
              <p>Av. Insurgentes Nte., Lindavista, Gustavo A. Madero, 07300 Ciudad de México, CDMX</p>
            </div>
          </div>
        </div>

      </div>

      <div className="col-lg-6">
        <form id="form-demo" onSubmit={onSumit} className={styleTemplate2['php-email-form']}>
          <div className="row">
            <div className={`col form-group ${styleTemplate2['form-group2']}`}>
              <input type="text" name="nombre" value={nombre} className="form-control" id="name" placeholder="Nombre" onChange={onInputChange} required/>
            </div>
          </div>
          <div className={`form-group ${styleTemplate2['form-group2']}`}>
            <textarea className="form-control" name="confirmacion" value={confirmacion} rows="5" placeholder="Mensaje" onChange={onInputChange} required></textarea>
          </div>
          <div className="text-center"><button type="submit">Enviar Confirmación</button></div>
        </form>
      </div>

    </div>

  </div>
</section>
  )
}
