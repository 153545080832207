import NosotrosModule from './componentes/Nosotros';
import Spacer from '../Comun/Componentes/Spacer';
import { useState } from 'react';
import useWindowsSize from '../Comun/Hooks/useWindowsSize';
import { Helmet } from 'react-helmet';
import { AdsComponent } from '../adsense/AdsComponent ';

const Nosotros = () => {
  const { innerWidth, innerHeight } = useWindowsSize();
  const [cardHeight, setCardHeight] = useState();

  let size = (Math.floor(innerHeight) - 200 - Math.floor(cardHeight)) / 2;

  let sizeUp = Math.floor(size + 28);
  let sizeDown = Math.ceil(size - 28);

  if (size < 1) {
    sizeUp = 50 + 56;
    sizeDown = 50;
  }

  let animation = 'flip-up';
  if (innerWidth <= 480) {
    animation = 'zoom-in-up';
  }

  return (
    <>
      <Helmet>
        <title>Nosotros | Click Invitaciones</title>
        <meta
          content="Invitaciones completamente digitales para todo tipo de eventos al alcance de un clic"
          name="description"
        ></meta>
        <meta
          content="invitaciones, invitaciones digitales, eventos, bodas, invitaciones bodas, invitaciones bautizo, invitaciones eventos"
          name="keywords"
        ></meta>
      </Helmet>
      <Spacer space={sizeUp} />
      <NosotrosModule animation={animation} setCardHeight={setCardHeight} />
      <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
      </div>
      <Spacer space={sizeDown} />
    </>
  );
};

export default Nosotros;
