

export const TextoPrivacidad = () => {
  return (
    <div className="container">
        <div style={{height:'150px'}}></div>
        <div className="row">
            <div className="col card shadow p-2">
                <h4 style={{textAlign:'center', fontWeight: 'bold'}}>Aviso de Privacidad</h4>
                <p style={{textAlign:'justify'}}>Click Invitaciones podrá utilizar, imprimir, reproducir y publicar la(s) imagen(es) (fotografías y/o vídeos) de aquellas personas que acudan o participen en nuestras invitaciones, campañas, así como las imágenes publicadas por los participantes en eventos, dinámicas o actividades en las que aparezcan personas identificables en medios impresos, electrónicos, sitios web, redes sociales, newsletters, memorias fotográficas y publicaciones, en todas sus manifestaciones, sus interpretaciones o ejecuciones, sus ediciones, sus emisiones, sus fotogramas o videogramas.</p>
                <p style={{textAlign:'justify'}}>Asimismo, podremos republicar dentro de nuestras redes sociales y sitios web, las imágenes que los visitantes o seguidores publiquen en nuestro perfil, las que resulten seleccionadas de los usuarios que participen en alguna dinámica a través de este medio y aquellas en las que se utilicen “hashtags” o etiquetas con alusiones a alguna de nuestra marca, o eventos, a fin de compartir las experiencias de la comunidad. En caso de que alguna de estas imágenes sea adecuada para su uso con fines publicitarios, lo contactaremos para solicitar su consentimiento expreso en términos del artículo 87 de la Ley Federal del Derecho de Autor. Sin previa autorización no revelará, utilizará, ni explotará comercialmente las imágenes o fotografías publicadas por los usuarios en sus cuentas personales (o su “Perfil”) dentro de las redes sociales.</p>
                <p style={{textAlign:'justify'}}>Click Invitaciones será responsable del uso y protección de datos personales y no revelará ningún tipo de información relacionada a externos.</p>
                <p style={{textAlign:'justify'}}>El uso indebido de cualquiera de las anteriores representará una responsabilidad legal.</p>
            </div>
        </div>
        <div style={{height:'100px'}}></div>  
    </div>
  )
}
