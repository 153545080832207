import React from 'react'

export const ServiciosTemplte3 = ({styleTemplate3}) => {
  return (
    <>
    <section id="services" className={styleTemplate3.services}>
  <div className="container" data-aos="fade-up">

    <div className={styleTemplate3['section-title']}>
      <h2>Servicios</h2>
      <p style={{fontWeight: 'bold'}}>Hoy el día es tuyo y nada más que tuyo, y, en él, tú eres la estrella que brilla más fuerte. ¡Felicidades por tus 15 años!</p>
    </div>

    <div className="row">
      <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-friends" width="44"
              height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="7" cy="5" r="2" />
              <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
              <circle cx="17" cy="5" r="2" />
              <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
            </svg></i>
          <h4><a href="#" style={{textDecoration: 'none'}}>Código de Vestimenta</a></h4>
           <p style={{fontSize:'16px'}}>Formal/Smart Casual</p>
            <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-gift" width="44"
              height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <rect x="3" y="8" width="18" height="4" rx="1" />
              <line x1="12" y1="8" x2="12" y2="21" />
              <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
              <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
            </svg></i>
          <h4>
            <a href="#" style={{textDecoration: 'none'}}>Mesa de regalos</a></h4>
               <p style={{fontSize:'16px'}}>Da click en el enlace!! <span style={{visibility:'hidden'}}>Regalos</span> <br/><a href="https://google.com">Regalos</a></p>
            <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="300">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-castle"
              width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
              strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M15 19v-2a3 3 0 0 0 -6 0v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14h4v3h3v-3h4v3h3v-3h4v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
              <line x1="3" y1="11" x2="21" y2="11" />
            </svg></i>
          <h4>
            <a href="#" style={{textDecoration: 'none'}}>Hospedaje</a></h4>
              <p style={{fontSize:'16px'}}>Da click en el enlace!!<br/><a href="https://google.com">Hospedajes Recomendados</a></p>
            <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="400">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mood-kid" width="44"
              height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="9" y1="10" x2="9.01" y2="10" />
              <line x1="15" y1="10" x2="15.01" y2="10" />
              <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
              <path d="M12 3a2 2 0 0 0 0 4" />
            </svg></i>
          <h4>
            <a href="#" style={{textDecoration: 'none'}}>Niños</a></h4>
            <p style={{fontSize:'16px'}}>Podrás traer a tus pequeños si lo necesitas</p>
            <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="500">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tools-kitchen-2"
              width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
              strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />
            </svg></i>
          <h4>
            <a href="#" style={{textDecoration: 'none'}}>Menú</a></h4>
          <ul className='mt-4'>
            <li>Entrada: Crema de nuez con cilantro</li>
            <li>Plato fuerte: Lomo de res al oporto</li>
            <li>Postre: Mousse de fresa</li>
          </ul>
          <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
      <div className="col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="600">
        <div className={styleTemplate3['icon-box']}>
          <i className="bi "><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-diamond" width="44"
              height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round"
              strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M6 5h12l3 5l-8.5 9.5a0.7 .7 0 0 1 -1 0l-8.5 -9.5l3 -5" />
              <path d="M10 12l-2 -2.2l.6 -1" />
            </svg></i>
          <h4><a href="#" style={{textDecoration: 'none'}}>Clave Interbancaria de la quinceañera</a></h4>
          <p style={{fontSize:'16px'}}>Puedes enviarle un pequeño detalle a la quinceañera en este dia especial: <span style={{fontWeight:'bold'}}>123 456 789 0987</span></p>
          <p style={{visibility: 'hidden'}}>Et harum quidem rerum facilis est et expedita distinctio. Nam
          </p>
        </div>
      </div>
    </div>

  </div>
</section>
    </>
  )
}
