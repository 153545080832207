import dayjs from 'dayjs';

export const countDownJs = (timesTampDays) => {
  const nowDayjs = dayjs();
  return {
    seconds: getSeconds(nowDayjs, timesTampDays),
    minutes: getMinutes(nowDayjs, timesTampDays),
    hours: getHours(nowDayjs, timesTampDays),
    days: getDays(nowDayjs, timesTampDays),
  };
};

const getDays = (nowDayjs, timesTampDays) => {
  const days = timesTampDays.diff(nowDayjs, 'days');
  if (days < 0) {
    return 'FELICIDADES!!!';
  }
  return `${days} días`;
};

const getHours = (nowDayjs, timesTampDays) => {
  const hours = timesTampDays.diff(nowDayjs, 'hours') % 24;
  if (hours < 0) {
    return 'DEL';
  }
  return `${hours} horas`;
};

const getMinutes = (nowDayjs, timesTampDays) => {
  const minutes = timesTampDays.diff(nowDayjs, 'minutes') % 60;
  if (minutes < 0) {
    return 'EQUIPO DE';
  }
  return `${minutes} minutos`;
};

const getSeconds = (nowDayjs, timesTampDays) => {
  const seconds = timesTampDays.diff(nowDayjs, 'second') % 60;
  if (seconds < 0) {
    return 'CLICK INVITACIONES';
  }
  return `${seconds} segundos`;
};
