import { useState } from "react";
import { saveForme } from "../../../config/firebase";
import { alertFire } from "../js/alert";

export const useForm = (initialForm={}) => {
const [formState, setFormState] = useState(initialForm);


  const onInputChange = ({target})=>{
    const {name, value} = target;
    setFormState({...formState, [name]:value});
  }

  const onSumit =(e)=>{
    e.preventDefault();
    const {nombre, whatsApp, correo, asunto} = formState;
    try {
        saveForme(nombre, whatsApp, correo, asunto);
        setFormState(initialForm);
        alertFire('Mensaje enviado', 'Pronto nos ponderemos en contacto');
    } catch (error) {
        alertFire('Error al enviar', 'Favor de intentar más tarde');
         setFormState(initialForm);
    }
  }

  const [isChecked, setIsChecked] = useState(false);


     const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const ClickPrivacidad = () => {
    if(isChecked===false){
return alertFire('Formulario incompleto', 'Favor de aceptar el aviso de privacidad');
    }
  }
  

  return {
    ...formState,
    formState,
    onInputChange,
    onSumit,
    ClickPrivacidad,
    handleOnChange,
    isChecked

  }
}
