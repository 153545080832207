import { nombramientos } from "../js/nombramientos"


export const NombramientosTemplate1 = ({styleTemplate1}) => {
  return (
    <section id="team" className={styleTemplate1.team}>
      <div className="container" data-aos="fade-up">

        <div className={styleTemplate1['section-title']}>
          <h2>Click Invitaciones</h2>
          <p>Nombramientos</p>
        </div>

        <div className="row nombramientos">
          {nombramientos.map((nombramiento)=>(
 <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={nombramiento.key}>
            <div className={styleTemplate1.member} data-aos="fade-up" data-aos-delay="100">
              <div className={styleTemplate1['member-img']}>
                <img src={nombramiento.img} className="img-fluid" alt=""/>
              </div>
              <div className={styleTemplate1['member-info']}>
                <h4>{nombramiento.nombre}</h4>
                <span>{nombramiento.cargo}</span>
              </div>
            </div>
          </div>
          ))}
        </div>

      </div>
    </section>
  )
}
