import Footer from '../../App/Comun/Componentes/Footer';
import HeaderC from '../../App/Comun/Componentes/Header';

import { Outlet } from 'react-router-dom';

import comunStyle from '../../App/Comun/Componentes/Comun.module.css';
import { WhatsApp } from '../../App/Comun/Componentes/WhatsApp';

const HomeLayout = () => {
  return (
    <div className={comunStyle.body}>
      <HeaderC />
      <Outlet />
      <WhatsApp />
      <Footer />
    </div>
  );
};

export default HomeLayout;
