import familir1 from '../../../../assets/img/templates/template3/img/index/nombramiento/pexels-pavel-danilyuk-7119174.jpg';
import familir2 from '../../../../assets/img/templates/template3/img/index/nombramiento/pexels-leeloo-thefirst-4661254.jpg';
import familir3 from '../../../../assets/img/templates/template3/img/index/nombramiento/comments-5.jpg';

export const familia = [
  {
    key: '0',
    familar: 'Luis',
    cargo: 'Padre',
    palabra:
      'Querida hija, Un día como hoy nació una flor como tú, Tienes un gran futuro por delante, y miles de oportunidades para construirlo como quieras. ¡Felicidades querida hija!',
    img: familir1,
  },
  {
    key: '1',
    familar: 'Lizet',
    cargo: 'Madre',
    palabra:
      'Querida hija, hoy tienes una fiesta de celebración, y parece que el regalo lo recibí yo al ver tan hermosa sonrisa en tu rostro. ¡Felicidades princesa!',
    img: familir2,
  },
  {
    key: '2',
    familar: 'David',
    cargo: 'Hermano',
    palabra:
      'Hoy estas tan bella y encantadora como solo una quinceañera puede estar. ¡Que te sobre la energía para celebrarlo! ¡Felicidades hermana!',
    img: familir3,
  },
];
