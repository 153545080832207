import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates";
import { useChangeScroll } from "../../../Comun/Hooks/useChangeScroll";

export const HeaderTamplete1 = ({styleTemplate1,setCardHeight}) => {
  const ref = useRef(null);

    const {navbar} = useChangeScroll();
    useEffect(() => {
      setCardHeight(ref.current.clientHeight);
    });
  

  return (
    <>
    <header ref={ref} id={styleTemplate1.header} className="fixed-top" style={navbar?{backgroundColor:'black'}:{backgroundColor:'#ffffff00'}}>
  <div className={`container ${styleTemplate1.container2} d-flex align-items-center justify-content-lg-between`}>

    <h1 className={`me-auto me-lg-0 ${styleTemplate1.logo}`}><Link style={{textDecoration:'none'}} to="/Invitaciones">Click Invitaciones<span>.</span></Link></h1>

    <nav id="navbar" className={`navbar ${styleTemplate1.navbar2} order-last order-lg-0`}>
      <ul>
        <li><a className="nav-link scrollto active" href="#hero">Inicio</a></li>
        <li><a className="nav-link scrollto" href="#about">Nosotros</a></li>
        <li><a className="nav-link scrollto" href="#services">Servicios</a></li>
        <li><a className="nav-link scrollto " href="#portfolio">Album Fotográfico</a></li>
        <li><a className="nav-link scrollto" href="#team">Nombramientos</a></li>
        <li><a className="nav-link scrollto" href="#contact">Dirección y Confirmación</a></li>
      </ul>
      <MenuTemplates template={1} color='white'/>
    </nav>
  </div>
</header>
    
    </>
  )
}
