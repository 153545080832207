import { useIsotopeReact } from "../../../Comun/Hooks/useIsotopeReact";
import { albumTemplate6 } from "../js/album-template6";

export const AlbumTemplate6 = ({styleTemplate6}) => {
   const {handleFilterKeyChange, activeFilter} = useIsotopeReact();
  return (
    <section id="portfolio" className={styleTemplate6.portfolio}>
      <div className="container" data-aos="fade-up">

        <div className={styleTemplate6['section-title']}>
          <h2>Clic Invitaciones</h2>
          <p>Álbum Fotográfico</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id={styleTemplate6['portfolio-flters']}>
              <li onClick={handleFilterKeyChange('*', 0)} className={`${activeFilter===0 ? styleTemplate6['filter-active'] : "" } ${styleTemplate6.albumFilterItem}`}>Todas</li>
              <li onClick={handleFilterKeyChange('filter-nosotros', 1)} className={`${activeFilter===1 ? styleTemplate6['filter-active'] : "" } ${styleTemplate6.albumFilterItem}`}>Nosotros</li>
              <li onClick={handleFilterKeyChange('filter-viajes', 2)} className={`${activeFilter===2 ? styleTemplate6['filter-active'] : "" } ${styleTemplate6.albumFilterItem}`}>Viajes</li>
              <li onClick={handleFilterKeyChange('filter-familia', 3)} className={`${activeFilter===3 ? styleTemplate6['filter-active'] : "" } ${styleTemplate6.albumFilterItem}`}>Familia</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
{albumTemplate6.map(photo=>(
<div key={photo.key} className={`col-6 col-sm-6 col-lg-4 col-md-6 ${styleTemplate6['portfolio-item']} portfolio-item ${photo.filter}`}>
            <div className="portfolio-wrap">
              <img  src={photo.img} className="img-fluid" alt=""/>
            </div>
          </div>
))}    

        </div>

      </div>
    </section>
  )
}
