import { Confetti } from "../../../Comun/Componentes/Confetti"



export const HeroTemplate6 = ({styleTemplate6,size}) => {
  return (
    <section id="hero" className={`${styleTemplate6.hero} d-flex flex-column justify-content-end align-items-center`} style={{ objectFit: "cover", height: size }}>
    <div id="heroCarousel" data-bs-interval="3000" className="container carousel carousel-fade" data-bs-ride="carousel">

      <div className="carousel-item active">
        <div className={`carousel-container ${styleTemplate6['carousel-container']}`}>
          <h2 className="animate__animated animate__fadeInDown">Bienvenidos a nuestra boda</h2>
          <p className="animate__animated fanimate__adeInUp"> “El amor se compone de una sola alma que habita en dos cuerpos”</p>
        </div>
      </div>

      <div className="carousel-item">
        <div className={`carousel-container ${styleTemplate6['carousel-container']}`}>
          <h2 className="animate__animated animate__fadeInDown">Un día importante y eres parte de el.</h2>
          <p className="animate__animated animate__fadeInUp">“Amar no es mirarse el uno al otro, es mirar juntos es la misma dirección”</p>
        </div>
      </div>

      <div className="carousel-item">
        <div className={`carousel-container ${styleTemplate6['carousel-container']}`}>
          <h2 className="animate__animated animate__fadeInDown">Gracias por acompañarme</h2>
          <p className="animate__animated animate__fadeInUp">“Andábamos sin buscarnos, pero sabiendo que andábamos para encontrarnos”</p>
        </div>
      </div>

      <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
        <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
      </a>

      <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
        <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
      </a>

    </div>

    {/* <svg className={styleTemplate6['hero-waves']} xmlns="<http://www.w3.org/2000/svg>" link="<http://www.w3.org/1999/xlink>" viewBox="0 24 150 28 " preserveAspectRatio="none">
      <defs>
        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
      </defs>
      <g className="wave1">
        <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"/>
      </g>
      <g className="wave2">
        <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"/>
      </g>
      <g className="wave3">
        <use href="#wave-path" x="50" y="9" fill="#fff"/>
      </g>
    </svg> */}
<Confetti />
  </section>
  )
}
