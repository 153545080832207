import { serviciosTemplate5 } from "../js/servicios-template5";
import { Confetti } from "../../../Comun/Componentes/Confetti";

export const ServiciosTemplate5 = ({ styleTemplate5 }) => {
  return (
    <div id="services" className={styleTemplate5.features}>
      <div className={`container ${styleTemplate5.container2}`}>
        <div className={styleTemplate5["section-title"]}>
          <h2>Detalles</h2>
          <p>
            Queremos que todo salga perfecto para ti y para nosotros aquí hay
            algunos detalles del Baby Shower
          </p>
        </div>
        <div className="row">
          {serviciosTemplate5.map((servicio) => (
            <div
              key={servicio.title}
              className={`col-lg-4 col-md-6 ${styleTemplate5["icon-box"]}`}
              data-aos="zoom-out-up"
            >
              <div className={styleTemplate5.icon}>
                <i className="bi">{servicio.icon}</i>
              </div>
              <h4 className={styleTemplate5.title}>
                <a href="" style={{ textDecoration: "none" }}>
                  {servicio.title}
                </a>
              </h4>
              <p className={styleTemplate5.description}>{servicio.text}</p>
              {servicio?.link != undefined ? <a href={servicio.link} target="_blank">Mesa de Regalos</a>: ""}
              {servicio?.cuenta != undefined ? <p className="fw-bolder">{servicio.cuenta}</p>: ""}
            
              
            </div>
          ))}
        </div>
      </div>
      {/* <Confetti /> */}
    </div>
  );
};
