import { Link } from "react-router-dom";

const Paquetes2 = () => {
return (
<div className="col-xs-12 col-md-6 col-lg-5 my-2 ">
  <div className="card shadow p-3" data-aos="fade-up" data-aos-duration="2000" style={{ height: "100%" }}>
    <div className="card-body">
      <h5 className="text-center fs-1 fw-bold" style={{color:'black'}}>Paquete Premium</h5>
      <hr style={{color:'black'}} />
      <p style={{color:'black'}}>En esta invitación encontrarás:</p>
      <ul style={{color:'black'}}>
        <li>Todos los beneficios del Paquete Básico.</li>
        <li>Cuenta Regresiva.</li>
        <li>
          Apartado donde tus invitados podrán confirmar su asistencia.
        </li>
        <li>
          Puedes seleccionar entre confeti o fuegos artificiales en tu invitación, puede ser solo el día del evento o
          desde el primer día con este asombroso efecto!!!!
        </li>
        <li>
          Una página donde podrás ver todas las confirmaciones y mensajes
          que tus seres queridos te escriban.
        </li>
        <li>
          Este paquete también incluye música en tu invitación. Agrega tu
          canción favorita, aquella que más te identifica!
        </li>
        <li>Invitación activa por 2 meses después del evento.</li>
      </ul>
      <p style={{color:'black'}}>
        Envíos ilimitados, puedes compartir tu invitación cuantas veces
        quieras por WhatsApp o cualquier red social.
      </p>
      <br />
      <hr style={{color:'black'}} />
      <div></div>
      <p style={{color:'black'}} className=" fs-1 fw-bold">MXN $1800.00</p>
      <div className="row justify-content-center">
        <div className="col-6 d-flex justify-content-start">
          <Link to="/preguntas-frecuentes">
          <button className="btn btn-warning">
            Proceso de pago
          </button>
          </Link>
        </div>
        <div className="col-6 d-flex justify-content-start">
          <a href="https://api.whatsapp.com/send?phone=5638481998" rel="noopener noreferrer" target="_blank">
            <button className="btn btn-primary">
              Comprar
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
);
};

export default Paquetes2;