
import { useIsotopeReact } from '../../../Comun/Hooks/useIsotopeReact'
import { albumTemplate1 } from '../js/album-temaplate1';

export const AlbumTemplate1 = ({styleTemplate1}) => {
   const {handleFilterKeyChange, activeFilter} = useIsotopeReact();
  return (
    <section id="portfolio" className={styleTemplate1.portfolio}>
  <div className="container" data-aos="fade-up">

    <div className={styleTemplate1['section-title']}>
      <h2>Click invitaciones</h2>
      <p>Nuestra historia</p>
    </div>

    <div className="row" data-aos="fade-up" data-aos-delay="100">
      <div className="col-lg-12 d-flex justify-content-center">
        <ul id={styleTemplate1['portfolio-flters']}>
          <li onClick={handleFilterKeyChange('*', 0)} className={`${activeFilter===0 ? styleTemplate1['filter-active'] : "" } ${styleTemplate1.albumFilterItem}`} >Todas</li>
          <li onClick={handleFilterKeyChange('filter-nosotros', 1)}  className={`${activeFilter===1 ? styleTemplate1['filter-active'] : "" } ${styleTemplate1.albumFilterItem}`}>Nosotros</li>
          <li onClick={handleFilterKeyChange('filter-viajes', 2)}  className={`${activeFilter===2 ? styleTemplate1['filter-active'] : "" } ${styleTemplate1.albumFilterItem}`}>Viajes</li>
          <li onClick={handleFilterKeyChange('filter-familia', 3)}  className={`${activeFilter===3 ? styleTemplate1['filter-active'] : "" } ${styleTemplate1.albumFilterItem}`}>Familia</li>
        </ul>
      </div>
    </div>

    <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

{albumTemplate1.map(album => (
      <div key={album.key} className={`col-6 col-sm-6 col-lg-4 col-md-6 portfolio-item mt-3 ${album.filter}`}>
        <div className="portfolio-wrap">
          <a href={album.img}>
          <img src={album.img} className="img-fluid" alt=""/>
          </a>
        </div>
      </div>

))}



    </div>

  </div>
</section>
  )
}
