import { Outlet } from "react-router-dom";

const TemplatesLayout = () => {
  return (
    <>
    <Outlet/>
    </>
  );
};

export default TemplatesLayout;
