import { CardsTemplates, TitleInvitaciones } from "./components/index"
import "./card-invitaciones.css";
import { Helmet } from "react-helmet";
import { AdsComponent } from "../adsense/AdsComponent ";

export const Invitaciones = () => {

    return (
    <>
    <Helmet>
        <title>Invitaciones | Click Invitaciones</title>
        <meta
          content="Si estás buscando las invitaciones de bodas perfectas que reflejen tu estilo y personalidad, has llegado al lugar adecuado. En nuestra plataforma, encontrarás una amplia variedad de diseños personalizados de alta calidad que se adaptan a tus necesidades y gustos."
          name="description"
        ></meta>
        <meta
          content="Invitaciones de boda, Diseño personalizado, Novios, Estilo, Tarjetas de agradecimiento, Programas de ceremonia, Celebración, Detalles, Elegancia, Creatividad, Exclusividad, Calidad, Temática, Fotografías,Detalles de la boda"
          name="keywords"
        ></meta>
      </Helmet>
    
<div className="container-fluid">
    <TitleInvitaciones/>
    <CardsTemplates/>
</div>
<div className="container-fluid">
<div className="row justify-content-around">
        <div className="col-12 col-md-6">
          <AdsComponent />
        </div>
        <div className="col-12 col-md-6">
          <AdsComponent />
        </div>
      </div>
      </div>
    </>
  )
}
