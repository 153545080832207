import CarruselItem from "./CarruselItem";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import index0 from "../../../assets/img/servicios/0.jpg";
import index1 from "../../../assets/img/servicios/1.jpg";
import index2 from "../../../assets/img/servicios/2.jpg";
import index3 from "../../../assets/img/servicios/3.jpg";
import index4 from "../../../assets/img/servicios/4.jpg";

import "./Carrusel.css";

const Carrusel = () => {
  let servicios = [
    {
      titulo: "Boda",
      imagen: index0,
      descripcion:
        "Impresiona a todos tus invitados con las increíbles invitaciones en el mejor día de tu vida, compartiendo fantásticos momentos con tus seres queridos, nosotros queremos ser parte de ese momento.",
    },
    {
      titulo: "XV años",
      imagen: index1,
      descripcion:
        "Solo se cumplen una vez en la vida y tanto tus familiares como tus amigos deben estar presentes cumpliendo este gran sueño, nosotros queremos ser parte de ese momento",
    },
    {
      titulo: "Bautizos",
      imagen: index2,
      descripcion:
        "Comparte con alegría este gran momento con toda tu familia y amigos invitándolos con nuestras increíbles invitaciones llenas de creatividad y cariño,  queremos ser parte de ese momento.",
    },
    {
      titulo: "Eventos",
      imagen: index3,
      descripcion:
        "Reúne a tus personas favoritas  en los increíbles eventos con ayuda de nuestras fantásticas invitaciones, nosotros queremos ser parte de ese momento",
    },
    {
      titulo: "Baby Shower",
      imagen: index4,
      descripcion:
        "Invita a todos tus amigos y familiares a compartir una nueva etapa de tu vida, siendo parte de un momento increíble para ti, nosotros queremos ser parte de ese momento.",
    },
  ];

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid contenedor my-2">
      <div className="row px-3 py-5 justify-content-center">
        <div className="row text-center">
          <p className="text-espa">Nuestros servicios</p>
        </div>
        <div className="row px-4">
          <Slider {...settings}>
            {servicios.map((element, index) => (
              <CarruselItem key={index} info={element} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Carrusel;
