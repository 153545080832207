

import { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import dayjs from 'dayjs';
import { AlbumTemplate5, ConfirmacionUbicacion, DataTemplate5, FooterTemplate5, HeaderTemplate5, HeroTemplate5, NosotrosTemplate5, ServiciosTemplate5 } from './components';
import styleTemplate5 from './css/template5.module.css'
import useWindowsSize from '../../Comun/Hooks/useWindowsSize';
import Spacer from "../../Comun/Componentes/Spacer";

export const Invitacion5 = () => {

  let date = new Date
  date.setMonth(date.getMonth()+1)
    let dateEvent = dayjs(date);
    useEffect(() => {
       Aos.init({duration:2000});
    }, []);
  
    const { innerHeight } = useWindowsSize();
    const [cardHeight, setCardHeight] = useState();
  
    let size = Math.floor(innerHeight) - cardHeight;
    let sizeUp = cardHeight;

  return (
    <>
    <HeaderTemplate5 styleTemplate5={styleTemplate5} setCardHeight={setCardHeight}/>
    <Spacer space={sizeUp} />
    <HeroTemplate5 styleTemplate5={styleTemplate5} size={size}/>
    <DataTemplate5 styleTemplate5={styleTemplate5} dateEvent={dateEvent} />
    <NosotrosTemplate5 styleTemplate5={styleTemplate5} />
    <ServiciosTemplate5 styleTemplate5={styleTemplate5} />
    <AlbumTemplate5 styleTemplate5={styleTemplate5} />
    <ConfirmacionUbicacion styleTemplate5={styleTemplate5} />
    <FooterTemplate5 styleTemplate5={styleTemplate5} />
    </>
  )
}

