import imgPalabrasH from '../../../../assets/img/templates/comun/nombramientos/pexels-pavel-danilyuk-7119174.jpg';
import imgPalabrasM from '../../../../assets/img/templates/comun/nombramientos/pexels-leeloo-thefirst-4661254.jpg';

export const fracesPadrinos = [
  {
    key: 0,
    nombre: 'Luis',
    cargo: 'Padrino',
    frase:
      '“Por amor unieron sus vidas en matrimonio y de corazón les deseo todo lo mejor, sean muy felices y disfruten cada día el poder estar juntos”.',
    img: imgPalabrasH,
  },
  {
    key: 1,
    nombre: 'Victoria',
    cargo: 'Madrina',
    frase:
      '“Un matrimonio excepcional no se da cuando se casa una “pareja perfecta”. Se da cuando una pareja imperfecta aprende a disfrutar sus diferencias”.',
    img: imgPalabrasM,
  },
  {
    key: 2,
    nombre: 'Angel',
    cargo: 'Padrino',
    frase:
      '““El amor no da más que de sí mismo y no toma más que de sí mismo. El amor no posee nada y no quiere que nadie lo posea, porque el amor se sacia en el amor” - Khalil Gibran.',
    img: imgPalabrasH,
  },
  {
    key: 3,
    nombre: 'Luisa',
    cargo: 'Madrina',
    frase:
      '“Un matrimonio feliz es una larga conversación que siempre parece demasiado corta, espero de todo corazón que siempre se amen como este día”.',
    img: imgPalabrasM,
  },
];
