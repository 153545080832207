import React from 'react'
import { Helmet } from 'react-helmet';
import Spacer from '../Comun/Componentes/Spacer'
import { CradsBlog } from './components/CradsBlog';
import { Principal } from './components/Principal';
import { AdsComponent } from '../adsense/AdsComponent ';

export const Blog = () => {

  return (
    <>
      <Helmet>
        <title>Blog | Click Invitaciones</title>
        <meta
          content="En nuestro blog de bodas, encontrarás todo lo que necesitas saber para planificar la boda de tus sueños."
          name="description"
        ></meta>
        <meta
          content="Planificación de bodas, Vestidos de novia, Ideas de decoración para bodas, Fotografía de bodas, Comida y bebida en bodas, Destinos para bodas, Regalos de bodas, Consejos para novios, Belleza y cuidado personal para bodas, Tendencias en bodas, Bodas temáticas, Música y entretenimiento en bodas, Historias de amor y compromiso, Tradiciones de bodas en diferentes culturas, Lugares para bodas al aire libre, Detalles únicos para bodas, Etiqueta en bodas, Luna de miel y viajes de bodas, Bodas en el extranjero, Organización y coordinación de bodas"
          name="keywords"
        ></meta>
      </Helmet>
      <Spacer space={100} />
      <Principal />
      <CradsBlog/>
      <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
      </div>
      <Spacer space={50} />
    </>
  )
}
