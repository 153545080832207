

import { useSliderSlike } from '../../../Comun/Hooks/useSliderSlike';
import { fracesPadrinos } from '../js/frases-padrinos';
export const PalabrasTemplate1 = ({styleTemplate1}) => {
  
  const {Slider, settings}=useSliderSlike(1);
 
  return (
    <section id="testimonials" className={styleTemplate1.testimonials}>
  <div className={`container ${styleTemplate1.container2}`} data-aos="zoom-in">

    <div className={`${styleTemplate1['testimonials-slider']} swiper`} data-aos="fade-up" data-aos-delay="100">
      <div className="swiper-wrapper">

      <Slider {...settings}>
{fracesPadrinos.map(frase=> (
 <div key={frase.key} className={styleTemplate1['testimonial-item']}>
            <img src={frase.img}
              className={styleTemplate1['testimonial-img']} alt=""/>
            <h3>{frase.nombre}</h3>
            <h4>{frase.cargo}</h4>
            <p>
              <i className="bx bxs-quote-alt-left quote-icon-left"></i>
             {frase.frase}
              <i className="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
))}
              </Slider>
      </div>
    </div>

  </div>
</section>
  )
}
