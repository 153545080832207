import useWindowsSize from "../Hooks/useWindowsSize";


export const MenuTemplates = ({template, color='white'}) => {
     const { innerWidth } = useWindowsSize();
  return (
   <>
   {innerWidth <= 991 ?  <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasNavbar${template}`} aria-controls={`offcanvasNavbar${template}`}>
      <span className="bi bi-list" style={{fontSize:'28px', color: color}}></span>
    </button> : ''}
    <div className="offcanvas offcanvas-end" tabIndex="-1" id={`offcanvasNavbar${template}`} aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ol className="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li><a style={{textDecoration: 'none', color:'black'}} href="#hero">Inicio</a></li>
        <li><a style={{textDecoration: 'none', color:'black'}} href="#about">Nosotros</a></li>
        <li><a style={{textDecoration: 'none', color:'black'}} href="#services">Servicios</a></li>
        <li><a style={{textDecoration: 'none', color:'black'}} href="#portfolio">Álbum Fotográfico</a></li>
        <li><a style={{textDecoration: 'none', color:'black'}} href="#team">Nombramientos</a></li>
        <li><a style={{textDecoration: 'none', color:'black'}} href="#contact">Dirección y Confirmación</a></li>
        </ol>
      </div>
    </div>
   </>
  )
}
