
import { Link } from "react-router-dom";

export const FooterTemplate1 = ({styleTemplate1}) => {
  return (
    <>
    <footer id={styleTemplate1.footer}>
    <div className="container">
      <div className={styleTemplate1.copyright}>
        &copy; Copyright <strong><span> <Link style={{textDecoration:'none', color: 'white'}} to={'/'}>Click Invitaciones</Link></span></strong>. Todos los derechos reservados. <strong><span> <Link style={{textDecoration:'none', color: 'white'}} to={'/aviso-privacidad'}>Aviso de Privacidad</Link></span></strong>
      </div>
    </div>
  </footer>
    
    </>
  )
}
