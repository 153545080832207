import { Link } from "react-router-dom"
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates"

export const HeaderTemplate6 = ({styleTemplate6}) => {
  return (
    <header id="header" className={`fixed-top d-flex align-items-center  header-transparent ${styleTemplate6.header}`}>
  <div className={`container ${styleTemplate6.container} d-flex align-items-center justify-content-between`}>

    <div className={styleTemplate6.logo}>
      <h1><Link style={{textDecoration: 'none'}} to={"/Invitaciones"}>Click Invitaciones</Link></h1>
    </div>

    <nav id="navbar" className={styleTemplate6.navbar}>
      <ul>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto active ${styleTemplate6['nav-link1']}`} href="#hero">Inicio</a></li>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto ${styleTemplate6['nav-link1']}`} href="#about">Nosotros</a></li>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto ${styleTemplate6['nav-link1']}`} href="#services">Servicios</a></li>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto ${styleTemplate6['nav-link1']}`} href="#portfolio">Album de fotos</a></li>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto ${styleTemplate6['nav-link1']}`} href="#team">Nombramientos</a></li>
        <li><a style={{textDecoration: 'none'}} className={`nav-link scrollto ${styleTemplate6['nav-link1']}`} href="#contact">Confirmación y Dirección</a></li>
      </ul>
      <MenuTemplates template={1}/>
    </nav>

  </div>
</header>
  )
}
