import { useSliderSlike } from "../../../Comun/Hooks/useSliderSlike";
import { albumPhoto } from "../js/album-photo";


export const AlbumTemplate5 = ({styleTemplate5}) => {
    const {Slider, settings}=useSliderSlike(4);
  return (
    <section id="portfolio" className={styleTemplate5['recent-photos']}>
  <div className={`container ${styleTemplate5.container2}`} data-aos="fade-up" data-aos-anchor-placement="center-bottom">

    <div className={styleTemplate5['section-title']}>
      <h2>Recuerdos</h2>
      <p>Algunas fotos de nuestra familia y un poquito de la historia de Sofi.</p>
    </div>

    <div className="recent-photos-slider swiper">
      <div className="swiper-wrapper align-items-center">
        <Slider {...settings}>
          {albumPhoto.map(photo=>( <div key={photo.key} className="swiper-slide"><a href={photo.img}
            className="glightbox ms-5"><img src={photo.img}
              className="img-fluid p-1" alt=""/></a></div>))}
        </Slider>
      </div>
      <div className="swiper-pagination"></div>
    </div>

  </div>
</section>
  )
}
