import { useState } from "react";
import { alertFire } from "../js/alert";

export const useFormTemplates = (initialForm={}) => {
const [formState, setFormState] = useState(initialForm);


  const onInputChange = ({target})=>{
    const {name, value} = target;
    setFormState({...formState, [name]:value});
  }

  const onSumit =(e)=>{
    e.preventDefault();
    const {nombre, confirmacion} = formState;
    try {
        setFormState(initialForm);
        alertFire('Mensaje enviado', 'Tu mensaje a sido enviado');
    } catch (error) {
        alertFire('Error al enviar', 'Favor de intentar más tarde');
         setFormState(initialForm);
    }
  }

  

  return {
    ...formState,
    formState,
    onInputChange,
    onSumit

  }
}
