
import imgNosotros from '../../../../assets/img/templates/template5/img/index/pexels-kampus-production-7802428.jpg';

export const NosotrosTemplate5 = ({styleTemplate5}) => {
  return (
    <section id="about" className={styleTemplate5.about}>
  <div className={`container ${styleTemplate5.container2}`} data-aos="fade-right" data-aos-duration="1500">

    <div className={styleTemplate5['section-title']}>
      <h2>Baby Shower</h2>
      <p className='fs-4'>Los pies mas pequeños hacen las huellas mas grandes en nuestros corazones.</p>
    </div>

    <div className="row content">
      <div className="col-lg-6">
        <img src={imgNosotros} className="img-fluid" alt=""/>
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0">
        <p>
          Vamos a celebrar un gran día acompáñanos al Baby Shower de nuestro pequeña en camino
        </p>
        <ul>
          <li><i className="ri-check-double-line"></i> Habrá divertidos juegos y dinámicas</li>
          <li><i className="ri-check-double-line"></i> Compartiremos gran variedad de canapes</li>
          <li><i className="ri-check-double-line"></i> Muchísimo diversion!!!</li>
        </ul>
        <p>Los papás de la pequeña Sofia queremos invitarte a este dia tan especial, tenemos mucha ilusión de que estés con nosotros en este primer evento especial dedicado a Sofi y que tenga muchas evidencias de cuanto la amamos desde antes de su llegada.
        </p>
      </div>
    </div>

  </div>
</section>
  )
}
