

export const Servicios2Tempate1 = ({styleTemplate1}) => {
  return (
    <>
     <section id="services" className={styleTemplate1.services}>
  <div className="container" data-aos="fade-up">

    <div className={styleTemplate1['section-title']}>
      <h2>Click Invitaciones</h2>
      <p>Servicios</p>
    </div>

    <div className="row">
      <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-friends" width="44" height="44" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="7" cy="5" r="2" />
                <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                <circle cx="17" cy="5" r="2" />
                <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Código de Vestimenta</a></h4>
          <p>Formal/Smart Casual</p>
          <p style={{visibility:'hidden'}}>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className="bx "><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-gift" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5"
                stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="8" width="18" height="4" rx="1" />
                <line x1="12" y1="8" x2="12" y2="21" />
                <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
                <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Mesa de regalos</a></h4>
           <p style={{fontSize:'16px', textAlign:'center'}}>Da click en el enlace!! <br/><a href="https://google.com">Regalos</a></p>
          <p style={{visibility:'hidden'}}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-building-castle" width="44" height="44" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M15 19v-2a3 3 0 0 0 -6 0v2a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14h4v3h3v-3h4v3h3v-3h4v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                <line x1="3" y1="11" x2="21" y2="11" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Hospedaje</a></h4>
          <p style={{fontSize:'16px'}}>Da click en el enlace!!<br/><a href="https://google.com">Hospedajes Recomendados</a></p>
          <p style={{visibility:'hidden'}}>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className="bx "><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mood-kid" width="44" height="44" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="9" />
                <line x1="9" y1="10" x2="9.01" y2="10" />
                <line x1="15" y1="10" x2="15.01" y2="10" />
                <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                <path d="M12 3a2 2 0 0 0 0 4" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Niños no</a></h4>
          <p>Por logística del evento no se podrán traer niños menores de 12 años</p>
          <p style={{visibility:'hidden'}}>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className="bx "><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-tools-kitchen-2" width="44" height="44" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Menú</a></h4>
          <ul style={{fontSize:'14px', textAlign: 'justify'}}>
            <li>Entrada: Crema de champiñones</li>
            <li>Plato fuerte: Pierna en salsa de mostaza</li>
            <li>Postres: Helado frito</li>
          </ul>
          <p style={{visibility:'hidden'}}>Quis consequatur saepe eligendi voluptatem consequatur dolor consequuntur</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
        <div className={styleTemplate1['icon-box']}>
          <div className={styleTemplate1.icon}><i className={styleTemplate1.bx}><svg xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-diamond" width="44" height="44" viewBox="0 0 24 24"
                strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 5h12l3 5l-8.5 9.5a0.7 .7 0 0 1 -1 0l-8.5 -9.5l3 -5" />
                <path d="M10 12l-2 -2.2l.6 -1" />
              </svg></i></div>
          <h4><a href="" style={{textDecoration:'none'}}>Clave Interbancaria de los novios</a></h4>
          <p>Puedes darle un hermoso detalle a los novios: <span style={{fontWeight:'bold'}}>123 456 789 098</span></p>
          <p style={{visibility:'hidden'}}>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
        </div>
      </div>

    </div>

  </div>
</section>
    </>
  )
}
