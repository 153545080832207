
import img1Template5Slider from '../../../../assets/img/templates/template5/img/index/pexels-alex-tim-754953.jpg'
import img2Template5Slider from '../../../../assets/img/templates/template5/img/index/pexels-paola-vasquez-3593434.jpg'
import img3Template5Slider from '../../../../assets/img/templates/template5/img/index/pexels-vidal-balielo-jr-1682462.jpg'

export const HeroTemplate5 = ({styleTemplate5,size}) => {
  return (
    
   <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
    <div id='hero'></div>
  <div className="carousel-inner">
    <div className="carousel-item active" >
      <img src={img1Template5Slider}  data-bs-interval="2000" className="d-block w-100" alt="" style={{ objectFit: "cover", height: size }}/>
    </div>
    <div className="carousel-item">
      <img src={img2Template5Slider}  data-bs-interval="2000" className="d-block w-100" alt="" style={{ objectFit: "cover", height: size }}/>
    </div>
    <div className="carousel-item">
      <img src={img3Template5Slider}  data-bs-interval="2000" className="d-block w-100" alt="" style={{ objectFit: "cover", height: size }}/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  )
}
