import imganeAlbum1 from '../../../../assets/img/templates/template2/img/index/album/shoes-g618f1f84d_1280.jpg';
import imganeAlbum2 from '../../../../assets/img/templates/template2/img/index/album/christening-g009be10a8_1280.jpg';
import imganeAlbum3 from '../../../../assets/img/templates/template2/img/index/album/christening-g3f5e8a34f_1280.jpg';
import imganeAlbum4 from '../../../../assets/img/templates/template2/img/index/album/baptism-g82a397689_1280.jpg';
import imganeAlbum5 from '../../../../assets/img/templates/template2/img/index/album/child-g2fc49cd16_1280.jpg';
import imganeAlbum6 from '../../../../assets/img/templates/template2/img/index/album/religion-g83d10aec1_1280.jpg';
import imganeAlbum7 from '../../../../assets/img/templates/template2/img/index/album/christening-g5ceb451f7_1920.jpg';
import imganeAlbum8 from '../../../../assets/img/templates/template2/img/index/album/pexels-pixabay-38999.jpg';
import imganeAlbum9 from '../../../../assets/img/templates/template2/img/index/album/adult-g26e65a267_1280.jpg';

export const albumTemplate2 = [
  { key: 1, img: imganeAlbum1, filter: 'filter-familia' },
  { key: 2, img: imganeAlbum2, filter: 'filter-padrinos' },
  { key: 3, img: imganeAlbum3, filter: 'filter-familia' },
  { key: 4, img: imganeAlbum4, filter: 'filter-bautizo' },
  { key: 5, img: imganeAlbum5, filter: 'filter-padrinos' },
  { key: 6, img: imganeAlbum6, filter: 'filter-familia' },
  { key: 7, img: imganeAlbum7, filter: 'filter-bautizo' },
  { key: 8, img: imganeAlbum8, filter: 'filter-bautizo' },
  { key: 9, img: imganeAlbum9, filter: 'filter-padrinos' },
];
