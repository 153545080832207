import { useSliderSlike } from '../../../Comun/Hooks/useSliderSlike';
import { palabrasTemplate2 } from '../js/palabras-template2';

export const PalabrasTemplate2 = ({styleTemplate2}) => {
    const {Slider, settings}=useSliderSlike(3);
  return (
     <section id="testimonials" className={styleTemplate2.testimonials}>
  <div className={`container ${styleTemplate2.container2}`} data-aos="fade-up">
    <div className={styleTemplate2['section-title']}>
      <h2>Palabras de los Padrinos</h2>
      <p>Fuiste creado para ser completamente amado y adorado por toda tu familia.</p>
    </div>

    <div className={`${styleTemplate2['testimonials-slider']} swiper`} data-aos="fade-up" data-aos-delay="100">
      <div className="swiper-wrapper ">
<Slider {...settings}>
{palabrasTemplate2.map(palabra => (
  <div key={palabra.key} className={styleTemplate2['testimonial-wrap']}>
            <div className={styleTemplate2['testimonial-item']}>
              <img src={palabra.img}
                className={styleTemplate2['testimonial-img']} alt=""/>
              <h3>{palabra.nombre}</h3>
              <h4>{palabra.cargo}</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                {palabra.frase}
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
))}
</Slider>
      </div>
     
    </div>

  </div>
</section>
  )
}
