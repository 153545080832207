import {Helmet} from "react-helmet";
import Spacer from "../../Comun/Componentes/Spacer";
import img1 from '../../../assets/img/blog/luna/pexels-vlada-karpovich-4050300.jpg'
import img2 from '../../../assets/img/blog/luna/pexels-aysegul-alp-14452884.jpg'
import img3 from '../../../assets/img/portadas/template1.jpg'
import img4 from '../../../assets/img/blog/planifica/pexels-andrea-piacquadio-3757656.jpg'
import img5 from '../../../assets/img/blog/planifica/pexels-agung-pandit-wiguna-2085523.jpg'
import img6 from '../../../assets/img/blog/planifica/pexels-george-milton-7014337.jpg'
import img7 from '../../../assets/img/blog/planifica/pexels-ella-olsson-1640772.jpg'
import { AdsComponent } from "../../adsense/AdsComponent ";
export const PlanificaBoda = () => {
return (
<>
  <Helmet>
    <title>Boda de ensueño</title>
    <meta content="Boda de ensueño: Guía completa para organizar tu día especial" name="description">
    </meta>
    <meta
      content="Planificar una boda Guía de bodas, Consejos para bodas,Organización de bodas, Boda sin estrés, Novios felices, Consejos para novios,Boda perfecta, Guía completa de bodas, Arte de planificar una boda, Consejos para hacer realidad la boda de tus sueños, Planear una boda, Boda, Ceremonia de la boda, Recepción de la boda, Vestido de novia, Proveedores de la boda,Lista de invitados de la boda, Luna de miel, Presupuesto de la boda, Lugar de la boda, Fecha de la boda, Fotógrafo de la boda, Catering de la boda, DJ de la boda, Pastelero de la boda, Destino de luna de miel, Planificación de la boda,Consejos para la boda, Experiencia de la boda, Emociones de la boda, Disfrutar del proceso de la boda"
      name="keywords">
    </meta>
  </Helmet>

  <Spacer space={100} />
  <div className="container">
    <div className="row">
      <div className="col text-center">
        <h1>Boda de ensueño: Guía completa para organizar tu día especial.</h1>
      </div>
    </div>
    <div className="row mb-4">
         <div className="col">
          <div className="card p-3 shadow text-center">
              <p><strong>¿Te has comprometido recientemente y estás empezando a planificar tu boda? ¡Felicidades! </strong> Planificar una boda
        puede ser emocionante, pero también puede ser abrumador. Aquí te presento algunos consejos para planificar la
        mejor boda posible:</p>
          </div>
      </div>
    </div>
    <div className="row mb-5">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <h3 className="text-center">Establece un presupuesto</h3>
        <p>Antes de empezar a planificar tu boda, es importante que determines cuánto dinero puedes
          permitirte gastar. <strong>Haz una lista de todos los gastos que puedas prever</strong> , desde la elección del lugar hasta el
          pastel
          de bodas y el fotógrafo. Una vez que tengas una idea de lo que quieres gastar, trata de mantenerte dentro de
          ese
          presupuesto.</p>
          <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img1} alt="Establece un presupuesto" title="Establece un presupuesto"/>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <h3 className="text-center">Elige el lugar perfecto</h3>
        <p> <strong>¿Sueñas con una boda en la playa o prefieres un lugar más formal en una sala de banquetes?</strong>
          Decide qué tipo de ambiente te gustaría y empieza a buscar lugares que cumplan con tus expectativas. Considera
          la
          ubicación, la capacidad, la comodidad y la accesibilidad para tus invitados.</p>
          <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img2} alt="Elige el lugar perfecto" title="Elige el lugar perfecto"/>
      </div>
    </div>
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
    <div className="row mb-5">
      <div className="col">
        <h3 className="text-center">Envía las invitaciones con anticipación</h3>
       <p><strong>Asegúrate de enviar las invitaciones con suficiente anticipación</strong> para
        que
        tus invitados puedan planificar sus viajes. Esto es especialmente importante si tienes invitados que viven fuera
        de
        la ciudad o fuera del país.</p>
        <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img3} alt="Envía las invitaciones con anticipación" title="Envía las invitaciones con anticipación"/>
      </div>
    </div>
    <div className="row mb-5">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <h3 className="text-center">Contrata a un planificador de bodas</h3>
        <p>Si estás muy ocupado o simplemente no tienes tiempo para planificar tu boda,
          <strong>considera contratar a un planificador de bodas</strong> . Este profesional te ayudará a planificar todo, desde la
          elección del
          lugar hasta la decoración y el transporte. Un planificador de bodas puede ser una gran inversión si quieres
          asegurarte de que tu boda sea perfecta.</p>
          <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img4} alt="planificador de bodas" title="planificador de bodas"/>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        <h3>Elije el vestido y el traje perfectos</h3>
        <p> <strong>El vestido y el traje que lleves en tu boda son una parte importante de tu día especial</strong>. Dedica tiempo a buscar opciones que te hagan sentir cómodo y hermoso. Asegúrate de elegir un
          vestido y
          un traje que se adapten a la temática y estilo de la boda.</p>
          <img style={{'borderRadius': '10px'}} className="img-fluid shadow" src={img5} alt="vestido perfecto" title="vestido perfecto"/>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <h3 className="text-center">Contrata a un buen fotógrafo</h3>
        <p><strong>Tu boda será un momento único en tu vida</strong> y querrás tener recuerdos que duren para
        siempre. Asegúrate de contratar a un fotógrafo profesional que pueda capturar cada momento especial.</p>
        <div className="text-center">
        <img src={img6} style={{'borderRadius': '10px'}} className="img-fluid shadow" alt="Buen fotógrafo" title="Buen fotógrafo"/>
        </div>
      </div>
    </div>
    <div className="row mb-5">
      <div className="col">
      <h3 className="text-center">Planea una buena comida</h3>
      <p>La comida es una parte importante de cualquier boda, así que <strong>asegúrate de elegir un menú
    que tus invitados disfruten</strong>. Considera las preferencias de tus invitados y busca opciones que se adapten a tus
    necesidades y presupuesto.</p>
        <div className="text-center">
            <img src={img7} style={{'borderRadius': '10px'}} className="img-fluid shadow" alt="Buena comida" title="Buena comida"/>
        </div>
      </div>  
    </div>
    <div className="row">
      <div className="col">
         <p>
    <strong>Siguiendo estos consejos</strong>, puedes estar seguro de que tendrás una boda inolvidable. Recuerda que lo más importante es
    celebrar tu amor y compromiso con tu pareja, así que disfruta cada momento de tu día especial. <strong>¡Feliz planificación
    de bodas!</strong></p>
      </div>
    </div>
    <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
          <div className="col-12 col-md-6">
            <AdsComponent />
          </div>
        </div>
      </div>
  </div>
</>
)
}