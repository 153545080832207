import { Helmet } from "react-helmet"
import { TextoPrivacidad } from "./components/TextoPrivacidad"


export const AvisoPrivacidad = () => {
  return (
   <>
   <Helmet>
        <title>Aviso de privacidad | Click Invitaciones</title>
        <meta
          content="Invitaciones completamente digitales para todo tipo de eventos al alcance de un clicBienvenidos a nuestra página web de contacto para invitaciones de boda. Aquí podrás encontrar todo lo necesario para hacer de tu gran día una experiencia única e inolvidable. En nuestra plataforma, te ofrecemos una amplia variedad de diseños personalizados para invitaciones de boda que se ajustan a tus necesidades y gustos."
          name="description"
        ></meta>
        <meta
          content="Invitaciones de boda, Diseño de invitaciones de boda,Personalización de invitaciones de boda, Tarjetas de agradecimiento, Detalles de boda, Coordinación de invitaciones, Contacto para invitaciones de boda, Pedidos de invitaciones de boda, Consulta de precios de invitaciones de boda, Plazos de entrega de invitaciones de boda, Muestras de invitaciones de boda, Información de contacto"
          name="keywords"
        ></meta>
      </Helmet>
   <TextoPrivacidad/>
   
   </>
  )
}
