import { Confetti } from "../../../Comun/Componentes/Confetti"


export const HeroTemplate1 = ({styleTemplate1,size}) => {
  return (
      <section id="hero" className={`d-flex align-items-center justify-content-center ${styleTemplate1.hero}`} style={{ objectFit: "cover", height: size }}>
    <div className={`container ${styleTemplate1.container2}`}data-aos="fade-up">

      <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
        <div className="col-xl-6 col-lg-8">
          <h1>Luis y Mariel<span>.</span></h1>
          <h2>BODA</h2>
        </div>
      </div>
    </div>
    <Confetti/>
  </section>
  )
}
