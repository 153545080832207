import { Link } from "react-router-dom";
import comunStyle from "./Comun.module.css";

const Footer = () => {
  return (
    <footer className="container-fluid">
      <div className={`row align-items-center ${comunStyle.colorFooter}`}>
        <div className="col-xs-12 col-md-6">
          <p className={`${comunStyle.textoFooter} text-center fw-bold`}>
            Impresiona a tus invitados!!
          </p>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col-6">
              <p className="fw-bold fs-5">Ayuda</p>
              <Link to="/Nosotros" className={`${comunStyle.colorTextFooter}`}>
                <p>Nosotros</p>
              </Link>
              <Link to="/preguntas-frecuentes" className={`${comunStyle.colorTextFooter}`}>
                <p>Preguntas Frecuentes</p>
              </Link>
              <Link to="/aviso-privacidad" className={`${comunStyle.colorTextFooter}`}>
                <p>Aviso de privacidad</p>
              </Link>
            </div>
            {/* <div className="col-6">
                  <p className="fw-bold fs-5">Síguenos</p>
                  <img
                    className="img-redes"
                    src="assets/img/icons8-facebook-nuevo-96.svg"
                    alt="facebook"
                  />
                  <img
                    className="img-r edes"
                    src="assets/img/icons8-instagram.svg"
                    alt="Instagram"
                  />
                  <img
                    className="img-redes"
                    src="assets/img/tiktok-share-icon-black-1.svg"
                    alt="Tik tok"
                  />
                </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
