import { familia } from "../js/familia";


export const FamiliaTemplate3 = ({styleTemplate3}) => {
  return (
<>
<section id="team" className={`${styleTemplate3.team} ${styleTemplate3['section-bg']}`}>
      <div className={`container ${styleTemplate3.container2}`}data-aos="fade-up">

        <div className={styleTemplate3['section-title']}>
          <h2>Familia</h2>
          <p>Todos los conocen pero les presento a las personas mas importantes en mi vida.</p>
        </div>

        <div className="row familia d-flex justify-content-center">
            {familia.map((familiar)=>(
                <div className="mt-5 col-lg-6" key={familiar.key} data-aos="fade-up" data-aos-delay="100">
        <div className={`${styleTemplate3.member} d-flex align-items-start`}>
          <div className={styleTemplate3.pic}><img src={familiar.img} className="img-fluid" alt=""/></div>
          <div className={styleTemplate3['member-info']}>
            <h4>{familiar.familar}</h4>
            <span>{familiar.cargo}</span>
            <p style={{fontSize: '18px', textAlign: 'justify'}}>{familiar.palabra}</p>
          </div>
        </div>
      </div>
            ))}

        </div>

      </div>
    </section>
</>
  )
}
