import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates";
import useWindowsSize from "../../../Comun/Hooks/useWindowsSize";

export const HeaderTemplate4 = ({ styleTemplate4, setCardHeight }) => {
  const ref = useRef(null);
  const { innerWidth } = useWindowsSize();
  useEffect(() => {
    setCardHeight(ref.current.clientHeight);
  });


  return (
    <>
      <header
        ref={ref}
        id="header"
        className={`${styleTemplate4.header} fixed-top d-flex align-items-center`}
      >
        <div className="container d-flex align-items-center">
          <h1 className={`${styleTemplate4.logo} me-auto`}>
            <Link className={`${styleTemplate4.link} ${styleTemplate4.linkH}`} to="/Invitaciones">
              Click Invitaciones
            </Link>
          </h1>
          <div>
            {innerWidth > 991 && <div className="row align-items-center">
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#hero">Inicio</a></div>
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#about">Nosotros</a></div>
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#services">Información</a></div>
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#team">Invitados</a></div>
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#portfolio">Álbum Fotográfico</a></div>
              <div className={`col ${styleTemplate4.headerItems}`}><a className={`${styleTemplate4.link} ${styleTemplate4.headerItemsAnimation}`} href="#contact">Ubicación Y Confirmación</a></div>
            </div>}
            < MenuTemplates color="black"/>
          </div>
        </div>
      </header>
    </>
  );
};
