import { serviciosTemplate6 } from "../js/servicios-template6";

export const ServiciosTemplate6 = ({ styleTemplate6 }) => {
  return (
    <section id="services" className={styleTemplate6.services}>
      <div className="container">
        <div className={styleTemplate6["section-title"]} data-aos="zoom-out">
          <h2>Click Invitaciones</h2>
          <p>Detalles</p>
        </div>

        <div className="row">
          {serviciosTemplate6.map((servicio) => (
            <div key={servicio.title} className="col-lg-4 col-md-6 mt-5 p-2">
              <div
                className={`${styleTemplate6["icon-box"]} text-center`}
                data-aos="zoom-in-left"
              >
                <div className={styleTemplate6.icon}>
                  <i className="bi">{servicio.icon}</i>
                </div>
                <h4 className={styleTemplate6.title}>
                  <a href="">{servicio.title}</a>
                </h4>
                <p className={styleTemplate6.description}>
                  {servicio.description}
                </p>
                {servicio?.link != undefined ? (
                  <a href={servicio.link} target="_blank">
                    Mesa de Regalos
                  </a>
                ) : (
                  ""
                )}
                 {servicio?.hotel != undefined ? (
                  <a href={servicio.hotel} target="_blank">
                    Hospedaje
                  </a>
                ) : (
                  ""
                )}
                {servicio?.cuenta != undefined ? (
                  <p className="fw-bolder">{servicio.cuenta}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
