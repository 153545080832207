

export const NosotrosTemplate2 = ({styleTemplate2}) => {
  return (
   <>
    <section id="about" className={`${styleTemplate2.about} ${styleTemplate2['section-bg']}`}>
      <div className={`container ${styleTemplate2.container2}`} data-aos="fade-up">

        <div className="row no-gutters">
          <div className={`${styleTemplate2.content} col-xl-12 d-flex align-items-stretch`}>
            <div className={styleTemplate2.content}>
              <h3>Para mi hermoso hijo</h3>
              <p>
              Al llevarte en mi vientre, sentía cómo nuestros latidos se movían al mismo tiempo. Ahora que puedo tenerte entre mis brazos, no ha desaparecido esa sensación, seguimos formando parte de un mismo corazón.
              </p><p>
                Imaginaba tu risa y cómo sería tu rostro, antes de que pudiera sostenerte en mis manos. Te has convertido en el regalo más maravilloso que jamás haya podido recibir.
              </p>
            </div>
          </div>
          
        </div>

      </div>
    </section>
   </>
  )
}
