import slider1 from "../../../../assets/img/templates/template4/carrousel/t4Carrousel1.jpg"
import slider2 from "../../../../assets/img/templates/template4/carrousel/t4Carrousel2.jpg"
import slider3 from "../../../../assets/img/templates/template4/carrousel/t4Carrousel3.jpg"

import "./animationSpecsCarrusel.css"

import useSliderBootstrap from "../../../Comun/Hooks/useSliderBootstrap";

export const CarruselTemplate4 = ({ styleTemplate4,sizeCarrusel }) => {
  const images=[slider1,slider2,slider3]
  const slider = useSliderBootstrap(styleTemplate4,"sliderTemplate4",images,sizeCarrusel)
  return (
    <div id="hero">
    {slider}
    </div>
  );
};
