
import { Link } from "react-router-dom";
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates";

export const HeaderTemplate2 = ({styleTemplate2}) => {
  return (
    <>
    <header id="header" className={`${styleTemplate2.header} fixed-top d-flex align-items-center`}>
  <div className={`${styleTemplate2.container2} container d-flex align-items-center`}>
    <h1 className={`${styleTemplate2.logo} me-auto`}><Link style={{textDecoration:'none'}} to="/Invitaciones">Click Invitaciones<span>.</span></Link></h1>

    <nav id="navbar" className={`${styleTemplate2.navbar2} navbar order-last     order-lg-0 `}>
      <ul>
        <li><a className={` ${styleTemplate2['nav-link2']} nav-link scrollto active`} href="#hero">Inicio</a></li>
        <li><a className={`${styleTemplate2['nav-link2']} nav-link scrollto`} href="#about">Nosotros</a></li>
        <li><a className={`${styleTemplate2['nav-link2']} nav-link scrollto`} href="#services">Servicios</a></li>
        <li><a className={`${styleTemplate2['nav-link2']} nav-link scrollto`} href="#portfolio">Álbum Fotográfico</a></li>
        <li><a className={`${styleTemplate2['nav-link2']} nav-link scrollto`} href="#team">Nombramientos</a></li>
        <li><a className={`${styleTemplate2['nav-link2']} nav-link scrollto`} href="#contact">Dirección y Confirmación</a></li>
      </ul>
      
   <MenuTemplates template={2} color='black'/>

    </nav>
  </div>
</header>
    
    
    </>
  )
}
