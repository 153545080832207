
import { Link } from "react-router-dom";
import { MenuTemplates } from "../../../Comun/Componentes/MenuTemplates";
import { useChangeScroll } from "../../../Comun/Hooks/useChangeScroll";

export const HeaderTemplete3 = ({styleTemplate3}) => {
  const {navbar} = useChangeScroll();
  return (
  <header id="header" style={navbar?{backgroundColor:'rgba(155, 45, 137, 0.9)'}:{backgroundColor:'#ffffff00'}} className={`${styleTemplate3.header} fixed-top d-flex align-items-center`}>
  <div className={`container ${styleTemplate3.container2} d-flex align-items-center justify-content-between`}>

    <h1 className={styleTemplate3.logo}><Link style={{textDecoration:'none'}} to="/Invitaciones">Click Invitaciones</Link></h1>

    <nav id="navbar" className={`navbar ${styleTemplate3.navbar2}`}>
      <ul>
        <li><a className="nav-link scrollto active" href="#hero">Inicio</a></li>
        <li><a className="nav-link scrollto" href="#about">Yo</a></li>
        <li><a className="nav-link scrollto" href="#services">Servicios</a></li>
        <li><a className="nav-link scrollto " href="#portfolio">Álbum Fotográfico</a></li>
        <li><a className="nav-link scrollto" href="#team">Familia</a></li>
        <li><a className="nav-link scrollto" href="#contact">Dirección y Confirmación</a></li>
      </ul>
      <MenuTemplates template={1} color='white'/>
    </nav>

  </div>
</header>
  )
}
