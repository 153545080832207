import { useEffect } from "react";

export const AdsComponent  = () => {
     useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
            console.log(e)
        }
    },[]);
  return (
    <>
    <ins className="adsbygoogle"
     style={{display:"block", height: '450', width: '450'}}
     data-ad-format="fluid"
     data-ad-layout-key="-5n+cn+27-im+ni"
     data-ad-client="ca-pub-8055327257260413"
     data-full-width-responsive="true"

     data-ad-slot="3754799520"></ins>
    </>
  )
}
