

export const NosotrosTemplate6 = ({styleTemplate6}) => {
  return (
    <section id="about" className={styleTemplate6.about}>
      <div className="container">

        <div className={styleTemplate6['section-title']} data-aos="zoom-out">
          <h2>Click Invitaciones</h2>
          <p>Nosotros</p>
        </div>

        <div className="row content" data-aos="fade-up">
          <div className="col-lg-6">
            <p>
              Nos conocimos donde nunca creímos encontrar a alguien tan especial, terminando ese concierto le pedí su numero me acepto una cita, durante 6 meses estuvimos saliendo solo como amigos hasta que acepto ser mi novia, 2 años después hemos decidido dar un paso más en la relación.
            </p>
            {/* <ul>
              <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
              <li><i className="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
              <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
            </ul> */}
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
              Queremos que seas parte de este momento tan importante como seguramente ya lo hayas sido en nuestra relación.
            </p>
          </div>
        </div>

      </div>
    </section>
  )
}
