import { Link } from "react-router-dom";
import { cardBlogInfo } from "../js/card-blogs-info";

export const CradsBlog = () => {
return (
<>
    <div className="container-fluid p-3">
        <div className="row justify-content-evenly">
            {
            cardBlogInfo.map(text=>(
            <div key={text.key} className="mt-5 col-10 col-sm-10 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <Link to={text.link} style={{'color': 'black', 'textDecoration':'none'}}><div className="card shadow">
                    <img src={text.img} className="img-fluid card-img-top" title={text.alt} alt={text.alt}></img>
                    <div className="card-body">
                        <h5 className="card-title">{text.title}</h5>
                        <p className="card-text">{text.subTitle}</p>
                        <Link to={text.link} className="btn btn-primary">Ver más</Link>
                    </div>
                </div></Link>
            </div>
            ))
            }
        </div>
    </div>
</>
)
}