import { Icon } from "@iconify/react";

export const NosotrosTemplate4 = ({ styleTemplate4 }) => {
  return (
    <div id="about" className="about">
      <div className="container">
        <div
          className="row content"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="col-lg-6 pe-4">
            <h2 className={styleTemplate4.nosotrosTitle}>Nosotros</h2>
            <h3 className={styleTemplate4.nosotrosSubInfo}>
              Organización Nacional De Profesores Unidos
            </h3>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
              Somos la organización de profesores mas grande en el país
            </p>
            <ul className={styleTemplate4.nosotrosList}>
              <li className={styleTemplate4.nosotrosListItem}>
                <Icon icon="ri:check-double-line" color="#d9232d" /> Contamos con presencia en todos los estados de la republica.
              </li>
              <li className={styleTemplate4.nosotrosListItem}>
                <Icon icon="ri:check-double-line" color="#d9232d" /> Brindamos apoyo a profesores en comunidades rurales que necesiten orientación para implementar metodologías de enseñanza.
              </li>
              <li className={styleTemplate4.nosotrosListItem}>
                <Icon icon="ri:check-double-line" color="#d9232d" /> Orientamos a los profesores en su proceso con el gobierno para no ser rechazados.
              </li>
            </ul>
            <p className="fst-italic">
              Ven a la convención y conoce todas las herramientas que brindamos al volverte miembro de nuestra organización, ademas podrás tomar los talleres que serán completamente gratuitos con peso curricular.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
